import * as React from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "Assets/images/logo.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import notifBell from "Assets/icons/notif-bell.svg";
import NotificationsRequest from "./NotificationsRequest";

const Navbar = () => {
  let auth = useAuth();
  let navigate = useNavigate();
  let location = useLocation();

  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  const [notificationDenied, setNotificationDenied] = React.useState(
    window.localStorage.getItem("notificationDenied")
  );

  const renderAuthButtons = () => (
    <div className="auth-buttons">
      <Link to="/login">
        <div>Login</div>
      </Link>
      <Link to="/register">
        <div>Register</div>
      </Link>
    </div>
  );

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleLogout = () => {
    auth.signout(() => {
      navigate("/login", { replace: true });
    });
  };

  const handleDenyNotification = () => {
    setNotificationDenied("denied");
  };
  const handleAllowNotification = () => {
    setNotificationDenied(true);
  };

  const renderLeaderboardIcon = () => (
    <Link
      to="/leaderboard"
      style={{ marginRight: "15px", marginTop: "2px", color: "#fff" }}
    >
      🏆
    </Link>
  );

  let emailVerified = auth?.user?.emailVerified;
  let isAdmin = auth?.user?.isAdmin;

  return (
    <div className="navbar-container">
      <div className="nav-display">
        <Link to="/">
          <img className="logo" src={logo} alt="" />
        </Link>

        <div>
          {auth.user ? (
            <div className="nav-buttons">
              {!["/feed", "/admin"].includes(location.pathname) &&
                (emailVerified || location.pathname == "/" ? (
                  <Link to="/feed" className="to-app-btn">
                    <div>Back</div>
                  </Link>
                ) : (
                  <a className="to-app-btn" onClick={handleLogout} href="#">
                    <div>Logout</div>
                  </a>
                ))}
              {renderLeaderboardIcon()}
              {emailVerified && !isAdmin && (
                <Link to="/notifications" className="notif-btn">
                  <img src={notifBell} alt="" />
                </Link>
              )}
              <Link to="/profile">
                <img
                  src={auth.user.photo ?? logo}
                  alt=""
                  className="user-image"
                />
              </Link>
            </div>
          ) : (
            <div className="auth-section">
              <div className="web">{renderAuthButtons()}</div>
              <div className="mobile">
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={toggleMobileMenu}
                  size="2x"
                  color="#2f006b"
                />
                {showMobileMenu && (
                  <div onClick={toggleMobileMenu}>{renderAuthButtons()}</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="nav-sub-info-display">
        {emailVerified &&
          !notificationDenied &&
          !["/", "/notifications", "/error-register"].includes(
            location.pathname
          ) &&
          !isAdmin && (
            <NotificationsRequest
              isNav
              onDeny={handleDenyNotification}
              onAllow={handleAllowNotification}
            />
          )}
      </div>
      <Outlet />
    </div>
  );
};
export default Navbar;
