import "./styles/TransactionItem.scss";

const TransactionItem = ({ createdAt, reason, amount, isDebit }) => {
  createdAt = createdAt?.toDate?.() ?? new Date(createdAt);
  return (
    <div className="transaction-item">
      <div className="transaction-item__date">
        <span className="transaction-item__date-text">
          {createdAt.toDateString() + ", " + createdAt.toLocaleTimeString()}
        </span>
      </div>
      <div className="transaction-item__description">
        <span className="transaction-item__description-text">{reason}</span>
      </div>
      <div className="transaction-item__amount">
        <span
          className={`transaction-item__amount-text ${isDebit && "isDebit"}`}
        >
          {amount}
        </span>
      </div>
    </div>
  );
};

export default TransactionItem;
