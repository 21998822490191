import axios from "axios";
import { auth } from "Config/firebase";

export const postRequest = async (url, data, ignoreAuth) => {
  let token = !ignoreAuth ? await auth.currentUser?.getIdToken(true) : null;
  let headers = { "Content-Type": "application/json" };
  if (token) headers["authorization"] = `Bearer ${token}`;
  else {
    console.log("no token");
    if (!ignoreAuth) return;
  }

  const response = await axios({
    method: "POST",
    url,
    data,
    headers,
  });
  return response.data;
};

export const getRequest = async (url, ignoreAuth) => {
  let token = !ignoreAuth ? await auth.currentUser?.getIdToken(true) : null;
  let headers = { "Content-Type": "application/json" };
  if (token) headers["authorization"] = `Bearer ${token}`;
  else {
    console.log("no token");
    if (!ignoreAuth) return;
  }

  // send request using axios
  const response = await axios({
    method: "get",
    url,
    headers,
  });

  return response.data;
};
