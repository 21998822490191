import { db } from "Config/firebase";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import ethLogo from "Assets/icons/ethLogo.svg";

import image1 from "Assets/images/image1.svg";
import { leaderboardTypes } from "Utils/variables";
import { useEffect, useState } from "react";
import { useAuth } from "Utils/authentication";
import { convertToFixed } from "Utils/helpers";

const LeaderboardTable = ({ boardType }) => {
  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [userIsInList, setUserIsInList] = useState(false);

  const auth = useAuth();

  const handleSetList = (list) => {
    // if user is not in list, add the user info immediately after the 3rd position user
    let __list = list;
    const isInList = list.findIndex((item) => item?.id == auth.user?.id) > -1;

    if (auth.user) {
      setUserIsInList(isInList);
      if (!isInList) __list.splice(3, 0, auth.user);
    } else setUserIsInList(true); // because user data not fully loaded yet

    setList(__list);
  };

  useEffect(() => {
    (async () => {
      if (boardType) {
        setIsFetching(true);

        if (boardType == leaderboardTypes.earners.label) await fetchEarners();
        if (boardType == leaderboardTypes.pointers.label) await fetchPointers();
        if (boardType == leaderboardTypes.referrals.label)
          await fetchReferrals();

        setIsFetching(false);
      }
    })();
  }, [boardType]);

  const fetchEarners = async () => {
    await getDocs(
      query(collection(db, "users"), orderBy("earnings", "desc"), limit(25))
    ).then((querySnapshot) => {
      handleSetList(
        querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        })
      );
    });
  };
  const fetchPointers = async () => {
    await getDocs(
      query(collection(db, "users"), orderBy("points", "desc"), limit(25))
    ).then((querySnapshot) => {
      handleSetList(
        querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        })
      );
    });
  };

  const fetchReferrals = async () => {
    await getDocs(
      query(
        collection(db, "users"),
        orderBy("referralCount", "desc"),
        limit(25)
      )
    ).then((querySnapshot) => {
      handleSetList(
        querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        })
      );
    });
  };

  const determineBackground = (index) => {
    return index == 0
      ? "#c499ff"
      : index == 1
      ? "#e4cfff"
      : index == 2
      ? "#ece2f8"
      : "none";
  };

  const determinePosition = (index) => {
    return index == 0
      ? // <img src={ChampionTrophyImage} alt="" width="20px" height="40px" />
        "🏆"
      : index == 1
      ? "🥈"
      : index == 2
      ? "🥉"
      : index == 3
      ? `${!userIsInList ? "-" : index + 1}`
      : `${!userIsInList ? index : index + 1}`;
  };

  const isTopThree = (index) => index < 3;
  const isEarners =
    leaderboardTypes[boardType].value == leaderboardTypes.earners.value;

  return (
    <div className="leaderboard-table">
      <div className="position">
        <div className="header">POSITION</div>
        {auth.user && (
          <div className="body">
            {!isFetching &&
              list.map((user, i) => (
                <div key={i} style={{ background: determineBackground(i) }}>
                  <div
                    className={`position-number fw-bold me-3 ${
                      isTopThree(i) ? "champion" : ""
                    }`}
                  >
                    {determinePosition(i)}
                  </div>
                  <img src={user.photo ?? image1} alt="" />
                </div>
              ))}
          </div>
        )}
      </div>

      <div className="username">
        <div className="header">USERNAME</div>
        {auth.user && (
          <div
            className="body"
            style={isFetching ? { display: "block", margin: "auto" } : {}}
          >
            {isFetching ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              list.map((user, i) => (
                <div key={i} style={{ background: determineBackground(i) }}>
                  <div className="username-text">{user.username}</div>
                </div>
              ))
            )}
          </div>
        )}
      </div>

      <div className="points">
        <div className="header">
          {leaderboardTypes[boardType].tableName.toUpperCase()}
        </div>
        {auth.user && (
          <div className="body fw-bold">
            {!isFetching &&
              list.map((user, i) => (
                <div key={i} style={{ background: determineBackground(i) }}>
                  <div className={`points-text ${isEarners ? "d-flex" : ""}`}>
                    {isEarners && <img src={ethLogo} alt="" className="me-1" />}
                    {convertToFixed(user[leaderboardTypes[boardType].value])}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LeaderboardTable;
