import ProfileStakeItem from "Components/ProfileStakeItem";
import { db } from "Config/firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth } from "Utils/authentication";
import { usePrevious } from "Utils/hooks";
import "./styles/UserStakes.scss";

const expired = "expired";
const active = "active";
const pending = "pending";
const resolved = "resolved";

let filterTypes = [expired, active, pending, resolved];
let moveCount = 0;

const UserStakes = () => {
  const authContext = useAuth();

  const [activeFilter, setActiveFilter] = useState(filterTypes[0]);
  let [requestedStakes, setRequestedStakes] = useState([]);
  let [nextQuery, setNextQuery] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [isCheckingForValue, setIsCheckingForValue] = useState(null);

  useEffect(() => {
    return () => {
      // this is used to make sure the filter is reset to the first item when the component unmounts
      moveCount = 0;
    };
  }, []);

  const prev_authUser = usePrevious(authContext.user);
  useEffect(() => {
    if (!prev_authUser && authContext.user) {
      (async () => {
        setIsCheckingForValue(true);
        await fetchRequestedStakes(true);
      })();
    }
  }, [authContext.user]);

  const prev_activeFiler = usePrevious(activeFilter);
  useEffect(() => {
    if (prev_activeFiler !== activeFilter && authContext.user) {
      setRequestedStakes([]);
      setNextQuery(null);
      setIsEmpty(false);
      (async () => {
        await fetchRequestedStakes(isCheckingForValue, true);
      })();
    }
  }, [activeFilter]);

  const fetchRequestedStakes = async (fromMount, ignoreNextQuery) => {
    if (isFetching) return;
    setIsFetching(true);

    if (ignoreNextQuery) {
      nextQuery = null;
      requestedStakes = [];
    }

    const request_collection =
      activeFilter == pending
        ? "pendingStakes"
        : [active, expired].includes(activeFilter)
        ? "stakes"
        : "resolvedStakes";

    const expiration_query_params = [
      collection(db, request_collection),
      where("userID", "==", authContext.user.id),
      where("expiration", "<", new Date().toISOString()),
      orderBy("expiration"),
      orderBy("createdAt", "desc"),
    ];
    const active_query_params = [
      collection(db, request_collection),
      where("userID", "==", authContext.user.id),
      where("expiration", ">", new Date().toISOString()),
      orderBy("expiration"),
      orderBy("createdAt", "desc"),
    ];
    const regular_query_params = [
      collection(db, request_collection),
      where("userID", "==", authContext.user.id),
      orderBy("createdAt", "desc"),
    ];
    const reqQuery =
      nextQuery ?? activeFilter == expired
        ? query(...expiration_query_params, limit(10))
        : activeFilter == active
        ? query(...active_query_params, limit(10))
        : query(...regular_query_params, limit(10));

    await getDocs(reqQuery)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          setIsEmpty(true);
          if (fromMount) {
            // move to next filter type
            if (moveCount != filterTypes.length - 1) {
              let nextType = filterTypes[moveCount + 1];
              setActiveFilter(nextType);
              moveCount = moveCount + 1;
            } else setIsCheckingForValue(false); // this is used to stop search for filter option with values when all options have been checked
          }
          return;
        }

        setIsCheckingForValue(false); // this is used to stop search for filter option with values when values have been found

        let limitUsed = nextQuery ? 5 : 10;
        if (querySnapshot.size < limitUsed) {
          setIsEmpty(true);
        }

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const __nextQuery =
          activeFilter == expired
            ? query(
                ...expiration_query_params,
                limit(5),
                startAfter(lastVisible)
              )
            : activeFilter == active
            ? query(...active_query_params, limit(5), startAfter(lastVisible))
            : query(...regular_query_params, limit(5), startAfter(lastVisible));
        setNextQuery(__nextQuery);
        const fetched_stakes = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setRequestedStakes([...requestedStakes, ...fetched_stakes]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <div className="user-stakes">
      <div className="filter">
        <div>Filter by:</div>
        <select
          value={activeFilter}
          onChange={(e) => setActiveFilter(e.currentTarget.value)}
        >
          <option value={expired}>Expired</option>
          <option value={active}>Active</option>
          <option value={pending}>Pending</option>
          <option value={resolved}>Resolved</option>
        </select>
      </div>

      <div className="stakes-list">
        {requestedStakes.map((stake) => (
          <ProfileStakeItem
            key={stake.id}
            stakeData={stake}
            type={activeFilter}
          />
        ))}

        <div className="loader">
          {requestedStakes.length == 0 && !isFetching && (
            <div>
              <div>No {activeFilter} stakes at the moment</div>
              <div
                className="text-center mt-3 load-btn text-decoration-underline"
                onClick={() => {
                  fetchRequestedStakes();
                }}
              >
                Reload
              </div>
            </div>
          )}
          {isFetching ? (
            <div>Loading...</div>
          ) : !isEmpty ? (
            <div
              className="load-btn"
              onClick={() => {
                fetchRequestedStakes();
              }}
            >
              Load more
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserStakes;
