import logo from "Assets/images/logo.svg";
import ethLogo from "Assets/icons/ethLogo.svg";
import ThreeDotLoader from "Assets/images/three-dot-loader.gif";

const modalID = "PaymentProcessModal";

const PaymentProcessModal = ({
  amount,
  ethAmount,
  totalEthAmount,
  level,
  errorMessage,
  thisModal,
  onClose,
}) => {
  const handleCloseModal = () => {
    thisModal?.hide();
    onClose?.();
  };

  const handleNavigateProfile = () => {
    window.open("/profile", "_self");
  };

  return (
    <div
      className="modal fade"
      id={modalID}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content paymentProcessModal">
          <div>
            <img src={logo} />
          </div>

          {level == 1 && (
            <>
              <div className="fw-bold mb-3 d-flex align-items-center justify-content-center">
                Total = <img src={ethLogo} width="20px" height="20px" />{" "}
                {totalEthAmount}
              </div>
              <ol style={{ textAlign: "left" }}>
                <li>Kindly approve the transaction in your web3 wallet.</li>
                <li>
                  Then please wait a moment while we confirm your transaction
                </li>
              </ol>
            </>
          )}

          {level == 2 && (
            <>
              <div className="fw-bold mb-3 d-flex align-items-center justify-content-center">
                Total = <img src={ethLogo} width="20px" height="20px" />{" "}
                {ethAmount} <span className="ms-1">~ ${amount}</span>
              </div>
              <div>Processing...</div>
            </>
          )}

          {level == "success" && (
            <>
              <div className="fw-bold mb-3">
                <b>Your stake was created successfully</b>
              </div>
              <ol className="mb-5" style={{ textAlign: "left" }}>
                <li>Your stake is now pending review.</li>
                <li>If accepted, your stake will be live.</li>
                <li>
                  If rejected, your stake will be cancelled and stake amount
                  will be refunded to your profile balance.
                </li>
              </ol>
            </>
          )}

          {level == "error" && (
            <>
              <div className="fw-bold mb-3">
                <b>An error occurred</b>
              </div>
              <div className="mb-5" style={{ textAlign: "left" }}>
                <div className="mb-2">
                  <b>Error: </b>
                  {errorMessage ?? "We couldn't detect this error."}
                </div>
                <div>
                  Please reach out to{" "}
                  <span style={{ color: "#7000ff" }}>hello@trystakes.com</span>{" "}
                  if you need assistance resolving this error.
                </div>
              </div>
            </>
          )}

          {!["error", "success"].includes(level) && (
            <div>
              <img src={ThreeDotLoader} alt="" width={150} />
            </div>
          )}

          {["error", "success"].includes(level) && (
            <div className="d-flex justify-content-around">
              <div className="close-btn" onClick={handleCloseModal}>
                Close
              </div>
              {level == "success" && (
                <div className="success-btn" onClick={handleNavigateProfile}>
                  Go to profile
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentProcessModal;
