import { requestNotificationToken } from "Utils/notifications";
import "./styles/NotificationsRequest.scss";
import notifBell from "Assets/icons/notif-bell.svg";
import { useAuth } from "Utils/authentication";

const NotificationsRequest = ({ isNav, onDeny, onAllow }) => {
  const auth = useAuth();

  const handleDenyNotification = () => {
    window.localStorage.setItem("notificationDenied", "denied");
    onDeny?.();
  };

  const handleAllowNotification = async () => {
    const token = await requestNotificationToken(
      auth?.user?.id,
      auth?.user?.FCM_token
    );
    if (token) window.localStorage.setItem("notificationDenied", token);
    onAllow?.(token);
  };

  return isNav ? (
    <div className="notification-request isNav">
      <div className="header">
        <img src={notifBell} alt="" />
        <div>Notifications</div>
      </div>
      <div className="body">
        Allow notification access to get notified when important updates happen
      </div>
      <div className="btns">
        <div onClick={handleDenyNotification}>Later</div>
        <div onClick={handleAllowNotification}>Allow</div>
      </div>
    </div>
  ) : (
    <div className="notification-request notNav">
      <div className="header">
        <img src={notifBell} alt="" />
        <div>Notifications</div>
      </div>
      <div className="body">
        Allow notification access to get notified when important updates happen
      </div>
      <div className="btn" onClick={handleAllowNotification}>
        Allow
      </div>
    </div>
  );
};

export default NotificationsRequest;
