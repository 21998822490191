export const durationOptions = [
  { id: 3, value: "3", label: "3 Days", interest: "20" },
  {
    id: 5,
    value: "5",
    label: "5 Days",
    interest: "50",
  },
  {
    id: 7,
    value: "7",
    label: "7 Days",
    interest: "100",
  },
];

export const roles = {
  admin: "admin",
  user: "user",
  influencer: "influencer",
};

export const no_web3 = "no_web3";
export const no_network = "no_network";

export const leaderboardTypes = {
  earners: {
    label: "earners",
    value: "earnings",
    tableName: "amount",
  },
  pointers: {
    label: "pointers",
    value: "points",
    tableName: "points",
  },
  referrals: {
    label: "referrals",
    value: "referralCount",
    tableName: "referred",
  },
};
