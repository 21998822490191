import { auth } from "Config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import { usePrevious } from "Utils/hooks";
import "./styles/LoginPage.scss";

const LoginPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [email, setEmail] = useState(null);

  let navigate = useNavigate();
  let location = useLocation();
  let authContext = useAuth();

  let from;
  if (location.state?.from)
    from = location.state.from?.pathname + location.state.from.search;
  else from = "/feed";

  const prevUser = usePrevious(authContext.user);
  useEffect(() => {
    if (!prevUser && authContext.user) {
      navigate(authContext.user.isAdmin ? "/admin" : from, { replace: true });
    }
  }, [authContext.user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);

      let formData = new FormData(event.currentTarget);
      const user = {
        email: formData.get("email"),
        password: formData.get("password"),
      };

      await authContext.signin(user, (isAdmin) => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(!isAdmin ? from : "/admin", { replace: true });
      });
      setIsSubmitting(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!isSendingReset) {
      setIsSendingReset(true);
      if (!email) {
        alert("Please enter an email address");
        setIsSendingReset(false);
        return;
      }
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          alert("Password reset email sent");
        })
        .catch((error) => {
          alert(error.code);
          setIsSendingReset(false);
        });
      setIsSendingReset(false);
    }
  };

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit}>
        <div className="header">
          <div>Welcome Back!</div>
          <div>Log in and get back to winning</div>
        </div>

        <label>
          <div>Email</div>{" "}
          <input
            name="email"
            type="email"
            required
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </label>
        <br />

        <label>
          <div>Password</div> <input name="password" type="password" required />
        </label>
        <div className="forgot-pass">
          Forgot password?{" "}
          <a href="#" onClick={handlePasswordReset}>
            {!isSendingReset ? "Send link" : "Sending..."}
          </a>
        </div>
        <br />

        <button type="submit">
          {!isSubmitting ? "Log in" : "Logging in..."}
        </button>
        <div>
          New here? <Link to="/register">Register Now</Link>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
