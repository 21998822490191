import { VAPID_KEY } from "Config";
import { db, messaging } from "Config/firebase";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { getToken, onMessage } from "firebase/messaging";

export const requestNotificationToken = async (userID, userToken) => {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  let tokenReceived;

  await getToken(messaging, { vapidKey: VAPID_KEY })
    .then(async (currentToken) => {
      if (currentToken) {
        // save token to user profile in db
        tokenReceived = currentToken;

        if (userToken !== currentToken) {
          await updateDoc(doc(db, `users/${userID}`), {
            FCM_token: currentToken,
          });
        }

        if (!userToken) {
          await setDoc(doc(db, `FCM_notificationTokens/${userID}`), {
            tokens: [currentToken],
          });
        } else {
          await updateDoc(doc(db, `FCM_notificationTokens/${userID}`), {
            tokens: arrayUnion(currentToken),
          });
        }
      } else {
        console.error(
          "Can't access token. This is probably because you have not granted permission to the browser to send push notifications."
        );
      }
    })
    .catch((err) => {
      console.error(err);
      alert(
        "Can't access token. This is probably because you have not granted permission to the browser to send push notifications."
      );
    });
  return tokenReceived;
};

export const onMessageListener = () => {
  return onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
  });
};
