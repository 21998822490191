import { leaderboardTypes } from "Utils/variables";

const LeaderboardInfo = ({ boardType }) => {
  return (
    <div className="leaderboard-info">
      {boardType == leaderboardTypes.earners.label && <div></div>}

      {boardType == leaderboardTypes.pointers.label && (
        <div className="pointers">
          <ul>
            <li>Answering questions = 2 points</li>
            <li>Winning stakes = 5 points</li>
            <li>Referral = 10 points</li>
          </ul>
        </div>
      )}

      {boardType == leaderboardTypes.referrals.label && <div></div>}
    </div>
  );
};

export default LeaderboardInfo;
