import TransactionItem from "Components/TransactionItem";
import { API_URL } from "Config";
import { db } from "Config/firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth, useWeb3 } from "Utils/context/hooks";
import { convertToFixed, convertToMoney, hideAddress } from "Utils/helpers";
import { postRequest } from "Utils/requests";
import "./styles/UserWallet.scss";
import ethLogo from "Assets/icons/ethLogo.svg";
import { roles } from "Utils/variables";

const MIN_AMOUNT = 10;

const UserWallet = () => {
  const authContext = useAuth();
  const web3Context = useWeb3();

  const [transactions, setTransactions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [nextQuery, setNextQuery] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);
  let [amount, setAmount] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const ethProfile = web3Context.ethProfile;

  useEffect(() => {
    if (authContext.user) {
      (async () => await fetchTransactionHistory())();
    }
  }, []);

  const fetchTransactionHistory = async () => {
    if (isFetching) return;
    setIsFetching(true);

    // fetch all transactions, limit by 10
    const reqQuery =
      nextQuery ??
      query(
        collection(db, `users/${authContext.user.id}/transactions`),
        orderBy("createdAt", "desc"),
        limit(10)
      );

    await getDocs(reqQuery)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          setIsEmpty(true);
          return;
        }

        let limitUsed = nextQuery ? 5 : 10;
        if (querySnapshot.size < limitUsed) {
          setIsEmpty(true);
        }

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const __nextQuery = query(
          collection(db, `users/${authContext.user.uid}/transactions`),
          orderBy("createdAt", "desc"),
          limit(5),
          startAfter(lastVisible)
        );
        setNextQuery(__nextQuery);
        const fetched_transactions = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setTransactions([...transactions, ...fetched_transactions]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleAmountChange = (event) => {
    let value = event.target.value;
    if (isNaN(value)) return;
    value = value.replace(".", "");
    setAmount(value);
  };

  const handleSubmitWithdrawalRequest = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    amount = Number(amount);

    const usdWalletBalance = +(walletBalance * ethPrice).toFixed(2);

    if (amount > usdWalletBalance) {
      alert(
        `You don't have enough money to withdraw.\nYour balance is ETH${walletBalance}.\nAproximately $${usdWalletBalance}`
      );
      return;
    }

    if (amount < MIN_AMOUNT) {
      alert(`The minimum withdrawal amount is $${MIN_AMOUNT}.`);
      return;
    }

    if (!ethProfile) {
      alert("Please connect your wallet first");
      return;
    }

    setIsSubmitting(true);

    const ethQuote = amount / ethPrice;

    await postRequest(`${API_URL}/withdrawals-requestWithdrawal`, {
      amount: ethQuote,
      address: ethProfile.eth_address,
    })
      .then(async (data) => {
        if (data.error)
          alert(data.error?.message ?? data.error ?? "An error occurred.");
        else {
          // update withdrawls
          if (data.data) {
            setTransactions([data.data, ...transactions]);
            setAmount("");
            await authContext.refreshUser();
            alert("Withdrawal successful");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        alert(err.response?.data?.error?.message ?? "An error occurred.");
      });

    setIsSubmitting(false);
  };

  const handleConnectWallet = async () => {
    setIsConnecting(true);
    await web3Context.getEthProfile();
    setIsConnecting(false);
  };

  const isInfluencer = authContext.user?.role == roles.influencer;
  const walletBalance = authContext?.user?.native_wallet ?? 0;
  const referralWalletBalance = authContext?.user?.referral_wallet ?? 0;
  const ethPrice = web3Context?.ethPrice;

  return (
    <div className="user-wallet">
      <div className="balance-display">
        <div>
          <div>Profile Balance</div>
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <img className="eth-logo" src={ethLogo} alt="" />
              {convertToFixed(walletBalance)}
            </div>
            {ethPrice && (
              <div className="ms-1" style={{ fontSize: "14px" }}>
                ≈ ${convertToMoney((walletBalance * ethPrice).toFixed(2))} ≈
              </div>
            )}
          </div>
        </div>
        <div>
          <div>{!isInfluencer ? "Referral Earnings" : "Referral Count"}</div>
          <div>
            {!isInfluencer
              ? `$${convertToMoney(+referralWalletBalance.toFixed(2))}`
              : `${convertToMoney(authContext?.user?.referralCount ?? 0)}`}
          </div>
        </div>
      </div>

      <div className="withdrawal">
        <div>
          <div>Withdraw ETH</div>
          {!ethProfile && (
            <div className="connect-btn" onClick={handleConnectWallet}>
              {!isConnecting ? "Connect wallet" : "Connecting..."}
            </div>
          )}
        </div>
        <div className="info">
          This will send the requested amount to{" "}
          {ethProfile ? (
            <b>{hideAddress(ethProfile.eth_address)}</b>
          ) : (
            "your connected ETH address"
          )}
          .
        </div>
        <form onSubmit={handleSubmitWithdrawalRequest}>
          <div>
            <input
              type="text"
              placeholder="Amount"
              name="amount"
              value={amount}
              onChange={handleAmountChange}
              required
            />
            <span className="stake-coin mt-1">
              Minimum: ${convertToMoney(MIN_AMOUNT)}
            </span>
          </div>
          <button type="submit">
            {isSubmitting ? "Please wait..." : "Withdraw"}
          </button>
        </form>
      </div>

      <div className="history">
        <div>Transaction History</div>

        <div className="list">
          {transactions.map((transaction, i) => (
            <div className="item" key={i}>
              <TransactionItem {...transaction} />
            </div>
          ))}
          {/* {transactions.length == 0 && (
            <div className="item">No transactions yet</div>
          )} */}
        </div>
      </div>

      <div className="loader">
        {transactions.length == 0 && !isFetching && (
          <div>
            <div>No transactions yet</div>
            <div
              className="text-center mt-3 load-btn text-decoration-underline"
              onClick={fetchTransactionHistory}
            >
              Reload
            </div>
          </div>
        )}
        {isFetching ? (
          <div>Loading...</div>
        ) : !isEmpty ? (
          <div className="load-btn" onClick={fetchTransactionHistory}>
            Load more
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserWallet;
