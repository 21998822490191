import NotificationItem from "Components/NotificationItem";
import NotificationsRequest from "Components/NotificationsRequest";
import { db } from "Config/firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth } from "Utils/authentication";
import "./styles/NotificationPage.scss";

const NotificationPage = () => {
  const auth = useAuth();

  const [notificationDenied, setNotificationDenied] = useState(
    window.localStorage.getItem("notificationDenied")
  );
  const [isFetching, setIsFetching] = useState(false);
  const [nextQuery, setNextQuery] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (!notificationDenied || notificationDenied == "denied") return;
    (async () => {
      await fetchNotifications();
    })();
  }, []);

  const fetchNotifications = async () => {
    if (isFetching) return;
    setIsFetching(true);

    // fetch latest notificatiosn from user notifications collection
    const reqQuery =
      nextQuery ??
      query(
        collection(db, `users/${auth.user?.id}/notifications`),
        orderBy("createdAt", "desc"),
        limit(10)
      );

    await getDocs(reqQuery)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          setIsEmpty(true);
          return;
        }

        let limitUsed = nextQuery ? 5 : 10;
        if (querySnapshot.size < limitUsed) {
          setIsEmpty(true);
        }

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const __nextQuery = query(
          collection(db, `users/${auth.user.id}/notifications`),
          orderBy("createdAt", "desc"),
          limit(5),
          startAfter(lastVisible)
        );
        setNextQuery(__nextQuery);

        const fetched_notifications = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setNotifications([...notifications, ...fetched_notifications]);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFetching(false));
  };

  const handleDenyNotification = () => {
    setNotificationDenied("denied");
  };
  const handleAllowNotification = () => {
    setNotificationDenied(true);
  };

  const isRequestDenied = notificationDenied === "denied";

  return (
    <div className="notification-page">
      {!notificationDenied || isRequestDenied ? (
        <NotificationsRequest
          onAllow={handleAllowNotification}
          onDeny={handleDenyNotification}
        />
      ) : (
        <div className="notification-page__content">
          <div>Notifications</div>

          <div className="notifications">
            {notifications.map((notification) => (
              <NotificationItem key={notification.id} item={notification} />
            ))}

            <div className="loader">
              {notifications.length == 0 && !isFetching ? (
                <div>
                  <div>No notifications at the moment</div>
                  <div
                    className="text-center mt-3 load-btn text-decoration-underline"
                    onClick={fetchNotifications}
                  >
                    Reload
                  </div>
                </div>
              ) : isFetching ? (
                <div>Loading...</div>
              ) : !isEmpty ? (
                <div className="load-btn" onClick={fetchNotifications}>
                  Load more
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationPage;
