import logo from "./logo.svg";
// import logoTag from "./logoTag.svg";
import logoTag from "./new_logoTag.svg";

const LogoWithTag = ({ id = "", className = "", style = {} }) => {
  return (
    <div
      id={id}
      className={className}
      style={{ width: "445.44px", height: "181.43px", ...style }}
    >
      <img src={logo} style={{ width: "100%", height: "81.8%" }} alt="" />
      {/* <img src={logoTag} style={{ width: "100%", height: "18.2%" }} alt="" /> */}
    </div>
  );
};

export default LogoWithTag;
