import { API_URL } from "Config";
import * as React from "react";
import { Link } from "react-router-dom";
import { getQueryParam } from "Utils/helpers";
import { postRequest } from "Utils/requests";
import "./styles/PaymentStatusPage.scss";

const PaymentStatusPage = () => {
  const [paymentSuccessful, setPaymentSuccessful] = React.useState(null);

  React.useEffect(() => {
    // get transaction reference from query params
    const tx_ref = getQueryParam("reference");

    (async () => {
      try {
        await postRequest(`${API_URL}/stakes-verifyStakePayment`, {
          tx_ref,
        }).then((data) => {
          console.log({ data });
          if (data.data) {
            if (data.data.successful) setPaymentSuccessful(true);
          } else setPaymentSuccessful(false);
        });
      } catch (error) {
        setPaymentSuccessful(false);
        return;
      }
    })();
  }, []);

  return (
    <div className="payment-status-page">
      {paymentSuccessful === null ? (
        <div className="loading">Getting payment status...</div>
      ) : (
        <div className={`${paymentSuccessful ? "success" : "failure"}`}>
          Your payment was {paymentSuccessful ? "successful" : "not successful"}
          {!paymentSuccessful && "\nor we could not verify your payment yet."}
        </div>
      )}

      <div className="buttons">
        <Link to="/profile">View your stakes</Link>
        <Link to="/feed">Go to feed page</Link>
      </div>
    </div>
  );
};

export default PaymentStatusPage;
