import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import "../styles/RegisterPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { removeSpecialCharacters } from "Utils/helpers";
import Web3WalletInfo from "Components/Web3WalletInfo";
import { useWeb3 } from "Utils/web3";
import Web3ConnectModal from "./Web3ConnectModal";

const RegisterPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [web3ConnectModal, setWeb3ConnectModal] = useState(null);
  const [connectWalletErrorType, setConnectWalletErrorType] = useState(null);

  let navigate = useNavigate();
  let auth = useAuth();
  let web3Context = useWeb3();

  const getAge = (dob) => {
    // get age from dob
    let today = new Date();
    let birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    // access web3 connect modal
    setWeb3ConnectModal(
      new window.bootstrap.Modal(document.getElementById("Web3ConnectModal"), {
        keyboard: false,
      })
    );
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);

      // check if web3 is connected
      // check age, if above 18, then register user

      if (!web3Context.ethProfile) {
        alert("Please connect a web3 wallet");
        document.getElementById("register-page").scrollIntoView({
          behavior: "smooth",
        });
        setIsSubmitting(false);
        return;
      }

      let formData = new FormData(event.currentTarget);

      const age = getAge(formData.get("dob"));
      if (age >= 18) {
        let newUser = {
          firstName: formData.get("firstName"),
          lastName: formData.get("lastName"),
          username: formData.get("username").toLowerCase(),
          email: formData.get("email"),
          password: formData.get("password"),
          photo: formData.get("profilePic"),
          dob: formData.get("dob"),
          phone: formData.get("phone"),
          eth_wallet: {
            address: web3Context.ethProfile.eth_address,
            balance: web3Context.ethProfile.eth_balance,
          },
        };
        const referral = formData.get("referral");
        if (referral) {
          newUser.referral = referral.toLowerCase();
        }

        await auth.register(newUser, () => {
          navigate("/feed", { replace: true });
        });
      } else alert("You must be 18 or above to register");

      setIsSubmitting(false);
    }
  };

  const handleConnectWalletFailure = (errorType) => {
    // show failure connect modal
    setConnectWalletErrorType(errorType);
    web3ConnectModal?.show();
  };

  return (
    <div id="register-page" className="register-page">
      <div className="header">
        <div>Create Your Account</div>
        <div>Connect your wallet, enter your details and start winning!</div>
      </div>

      <Web3WalletInfo
        onConnectFailure={handleConnectWalletFailure}
        ignoreAlert
        hideBalance
        displayAddress
      />

      <form onSubmit={handleSubmit}>
        <label>
          <div>First name</div>
          <input name="firstName" type="text" required />
        </label>
        <br />

        <label>
          <div>Last name</div>
          <input name="lastName" type="text" required />
        </label>
        <br />

        <label>
          <div>Username</div>
          <input
            name="username"
            type="text"
            pattern=".{4,15}"
            required
            onChange={(e) => {
              e.target.value = removeSpecialCharacters(
                e.target.value.toLowerCase()
              );
            }}
          />
          4 to 15 characters
        </label>

        <br />
        <label>
          <div>Email</div> <input name="email" type="email" required />
        </label>
        <br />
        <label>
          <div className="password-label">
            <div>Password</div>
            <FontAwesomeIcon
              icon={!showPassword ? faEye : faEyeSlash}
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            pattern="^.{6,}$"
            required
          />
          min 6 characters
        </label>
        <br />
        <label>
          <div>Profile picture</div>
          <input name="profilePic" type="file" accept="image/*" required />
        </label>
        <br />
        <label>
          <div>Date Of Birth</div>{" "}
          <input name="dob" type="date" placeholder="yyyy-mm-dd" required />
        </label>
        <br />
        <label>
          <div>Phone number</div> <input name="phone" type="tel" required />
          include country code
        </label>
        <br />

        <label>
          <div>
            Referred by{" "}
            <span style={{ fontSize: "14px", color: "#4A4A4A" }}>
              (optional)
            </span>
          </div>
          <input name="referral" type="text" pattern="^.{4,}$" />
          min 4 characters
        </label>

        <div className="confirmations">
          {/* confirm age is over 18 and accept terms */}
          <div>
            <input type="checkbox" required />
            <span>I am over 18 years old</span>
          </div>
          <div>
            <input type="checkbox" required />
            <span>
              I accept the{" "}
              <a
                href="https://docs.google.com/document/d/12H9WIbSTh-7igETaqXqc44yPnFZqb8W8g575o59Du5c/edit?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                terms and conditions
              </a>{" "}
              and{" "}
              <a
                href="https://docs.google.com/document/d/1e4C149D0JlnnOzp5AH0P3SM97xgmx59hM8DSf_mlk48/edit?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>
            </span>
          </div>
        </div>
        <button type="submit" id="register-button">
          {!isSubmitting ? "Register" : "Registering..."}
        </button>
        <div>
          Been in the game? <Link to="/login">Login</Link>
        </div>
      </form>

      <Web3ConnectModal
        thisModal={web3ConnectModal}
        purpose={connectWalletErrorType}
      />
    </div>
  );
};

export default RegisterPage;
