import { useNavigate } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import "./styles/ErrorRegisterPage.scss";

const VerifyEmailPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    auth.signout(() => {
      navigate("/login", { replace: true });
    });
  };
  return (
    <div className="error-register-page">
      <div>
        <div className="title">Your account didn't register properly</div>
        <div className="message">
          Hi there, an error occurred while creating your account.
          <br />
          Please make sure you have a good internet connection and register with
          a new email.
          <br />
          If you would still like to use this email for your account, please
          email us at <span>hello@trystakes.com</span>
        </div>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
