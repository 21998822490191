import ethLogo from "Assets/icons/ethLogo.svg";
import { API_URL } from "Config";
import { useState } from "react";
import { useAuth } from "Utils/authentication";
import { getDaysLeft } from "Utils/helpers";
import { postRequest } from "Utils/requests";
import "./styles/ProfileStakeItem.scss";

const pending = "pending";
const resolved = "resolved";
const expired = "expired";
const active = "active";

const ProfileStakeItem = ({ stakeData, type }) => {
  const authContext = useAuth();

  const [isClaiming, setIsClaiming] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);

  console.log({ stakeData });

  const createdAt = stakeData?.createdAt?.toDate() ?? new Date();
  const resolvedAt = stakeData?.resolvedAt?.toDate() ?? new Date();
  const expiration = stakeData?.expiration
    ? new Date(stakeData.expiration)
    : new Date();

  const daysLeft = getDaysLeft(expiration);

  const smallData = [
    {
      id: "stake",
      label: "Amount",
      value: stakeData.amount,
      isCoin: true,
    },
    {
      id: "reward",
      label: "Reward",
      value: stakeData.reward,
      isCoin: true,
    },
    {
      id: "duration",
      label: "Duration",
      value: `${stakeData.duration} days`,
      subValue: stakeData?.expiration
        ? `${Math.abs(daysLeft)} ${daysLeft < 0 ? "day passed" : "days left"}`
        : "",
    },
  ];

  const handleClaim = async () => {
    if (isClaiming) return;

    setIsClaiming(true);
    const stakeID = stakeData.id;

    await postRequest(`${API_URL}/stakes-claimStakeReward`, {
      stakeID,
    })
      .then(async () => {
        await authContext.refreshUser();
        setIsClaimed(true);
      })
      .catch((err) => {
        console.error(err);
        setIsClaiming(false);
      });

    setIsClaiming(false);
  };

  const isActive = type == active;
  const isPending = type == pending;
  const isResolved = type == resolved;
  const isExpired = type == expired;

  return (
    <div className="profile-stake-item">
      <div className="header">
        <div>
          <div>
            <span>{isPending ? "Created" : "Approved"}: </span>
            {createdAt.toDateString() + ", " + createdAt.toLocaleTimeString()}
          </div>
          {isResolved && (
            <div>
              <span>Resolved: </span>
              {resolvedAt.toDateString() +
                ", " +
                resolvedAt.toLocaleTimeString()}
            </div>
          )}
          {[active, expired].includes(type) && (
            <div>
              <span>{isActive ? "Expires" : "Expired"}: </span>
              {expiration.toDateString() +
                ", " +
                expiration.toLocaleTimeString()}
            </div>
          )}
        </div>
        <div>
          {isActive ? (
            <div style={{ backgroundColor: "#00830D" }}>Active</div>
          ) : isPending ? (
            <div style={{ backgroundColor: "#BC6600" }}>Pending review</div>
          ) : isExpired ? (
            <div style={{ backgroundColor: "#BF0404" }}>Expired</div>
          ) : (
            <div style={{ backgroundColor: "#BF0404" }}>
              <div>Resolved</div>
              <div>Reason: {stakeData.resolveReason}</div>
            </div>
          )}
        </div>
      </div>
      <div className={`top ${type != pending && "not-pending"}`}>
        {smallData
          .filter((d) =>
            [pending, resolved].includes(type) ? d.id != "reward" : true
          )
          .map((data) => (
            <div key={data.id}>
              <div>{data.label}</div>
              <div className="value">
                {data.isCoin &&
                  (!stakeData.fromReferralWallet ? (
                    <img src={ethLogo} alt="" />
                  ) : (
                    "$"
                  ))}
                {data.value}
              </div>
              {type != resolved && data.subValue && (
                <div style={{ fontSize: "14px" }}>{data.subValue}</div>
              )}
            </div>
          ))}
      </div>
      <div className="middle">
        <div>
          <div>Question</div>
          <div>{stakeData.question}?</div>
        </div>
        {[pending, resolved].includes(type) && (
          <div>
            <div>Answer</div>
            <div>{stakeData.answer}</div>
            {/* <div>Hints</div>
            <ul>
              {stakeData.hints.map((hint, i) => (
                <li key={i}>{hint}</li>
              ))}
            </ul> */}
          </div>
        )}
      </div>
      <div className="bottom">
        {type != pending && (
          <div>
            <div>{smallData[2].label}</div>
            <div className="value">{smallData[2].value}</div>
            {type != resolved && (
              <div style={{ fontSize: "14px" }}>{smallData[2].subValue}</div>
            )}
          </div>
        )}
      </div>

      {isExpired && (
        <div
          onClick={!isClaimed ? handleClaim : () => {}}
          className={`redeemBtn w-100 btn-success btn fs-5 ${
            isClaimed ? "opacity-25" : ""
          }`}
        >
          {isClaimed ? "Claimed" : isClaiming ? "Claiming..." : "Claim reward"}
        </div>
      )}
    </div>
  );
};

export default ProfileStakeItem;
