import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../Utils/authentication";

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    if (!auth.user) {
      navigate("/login", { replace: true, state: { from: location } });
    }

    if (auth.user && !auth.user.emailVerified && !auth.user.isAdmin) {
      navigate("/verify-email", { replace: true, state: { from: location } });
    }

    if (auth.user && !auth.user.username) {
      navigate("/error-register");
    }

    if (
      auth.user &&
      auth.user.isAdmin &&
      !location.pathname.includes("/profile")
    ) {
      navigate("/admin", { replace: true, state: { from: location } });
    }
  }, [auth.user, location.pathname]);

  return children;
}

export default RequireAuth;
