import * as React from "react";
import { API_URL } from "Config";
import { getRequest } from "Utils/requests";
import Web3 from "web3";
import { VALID_ETH_CHAINID } from "Config";
import { MAIN_ETH_ADDRESS } from "Config";
import { determineError, subscribeToEthPrice } from "Utils/helpers";
import { no_network, no_web3 } from "Utils/variables";

let Web3Context = React.createContext({
  ethPrice: null,
  ethProfile: null,
  getEthProfile: async (callback) => {},
  payEth: async (amount, callback) => {},
  updateEthBalance: async () => {},
});

function Web3Provider({ children }) {
  let [ethPrice, setEthPrice] = React.useState(null);
  let [ethProfile, setEthProfile] = React.useState(null);

  const getProvider = (ignoreAlert) => {
    let __provider = Web3.givenProvider || window.ethereum;
    if (!__provider) !ignoreAlert && alert("Please install a web3 wallet");
    return __provider;
  };

  React.useEffect(() => {
    // fetch eth price from backend ~ this triggers an update in db
    getRequest(`${API_URL}/misc-getETHPrice`, true).then((data) => {
      setEthPrice(data?.price);
    });

    // get realtime ethPrice everytime price is updated
    const ethPriceListenerUnsubscribe = subscribeToEthPrice(setEthPrice);
    return () => {
      ethPriceListenerUnsubscribe();
    };
  }, []);

  let getEthProfile = async (ignoreAlert) => {
    const provider = getProvider(ignoreAlert);
    if (!provider) return { error: no_web3 };

    const web3 = new Web3(provider);

    let connectError;

    const handleSetEthAccount = async (accounts) => {
      let eth_address, eth_balance;
      eth_address = accounts[0];

      if (eth_address) {
        // check chainId
        let chainId;
        await web3.eth.getChainId().then((__chainId) => {
          chainId = __chainId;
        });

        if (chainId != VALID_ETH_CHAINID) {
          connectError = no_network;
          !ignoreAlert &&
            alert("Please connect your wallet to Ethereum mainnet");
          setEthProfile(null);
          return;
        }

        await web3.eth.getBalance(eth_address).then((balance) => {
          eth_balance = web3.utils.fromWei(balance, "ether");
        });

        if (eth_balance) setEthProfile({ eth_address, eth_balance });
        else setEthPrice(null);
      } else setEthPrice(null);
    };

    try {
      await web3.eth
        .requestAccounts()
        .then(async (accounts) => await handleSetEthAccount(accounts))
        .catch((err) => {
          console.error(err);
          connectError = no_web3;
        });

      if (!connectError) {
        // activate listener for account/chain changes
        provider?.on(
          "accountsChanged",
          async (accounts) => await handleSetEthAccount(accounts)
        );
        provider?.on("chainChanged", () => window.location.reload());
      } else return { error: connectError };
    } catch (error) {
      console.error(error);
      return { error: no_web3 };
    }
  };

  let updateEthBalance = async () => {
    if (ethProfile) {
      const provider = getProvider(true);
      if (!provider) return;
      const web3 = new Web3(provider);

      let eth_balance;

      await web3.eth.getBalance(ethProfile.eth_address).then((balance) => {
        eth_balance = web3.utils.fromWei(balance, "ether");
      });
      if (eth_balance) setEthProfile({ ...ethProfile, eth_balance });
    }
  };

  let checkTransaction = async (txHash) => {
    const provider = getProvider();
    if (!provider) return;
    const web3 = new Web3(provider);
    let txReceipt;
    let isSuccessful, isFailed, isNull;

    await web3.eth
      .getTransactionReceipt(txHash)
      .then((receipt) => {
        txReceipt = receipt;
      })
      .catch((error) => {
        console.error(error);
      });

    if (txReceipt) {
      if (txReceipt.status == true) isSuccessful = true;
      else isFailed = true;
    } else {
      isNull = true;
    }

    return { isSuccessful, isFailed, isNull };
  };

  let payEth = async ({ amount, onSuccess, setNextLevel, setErrorMessage }) => {
    const provider = getProvider();
    if (!provider) return;
    const web3 = new Web3(provider);

    if (ethProfile?.eth_balance < amount) {
      // alert(
      //   "You do not have enough ETH in your web3 wallet.\nTry topping up your wallet balance or changing wallet account."
      // );
      setNextLevel("error");
      setErrorMessage(
        "You do not have enough ETH in your wallet.\nTry topping up your wallet or changing wallet account."
      );
      return;
    }

    await web3.eth
      .sendTransaction({
        from: ethProfile.eth_address,
        to: MAIN_ETH_ADDRESS,
        value: web3.utils.toWei(String(amount), "ether"),
      })
      .then(async (receipt) => {
        console.log(receipt);
        if (receipt) {
          if (receipt.status == true) {
            await onSuccess(receipt);
          } else {
            // wait 5 secs then check if the transaction was mined yet.
            setTimeout(() => {
              (async () => {
                const result = await checkTransaction(
                  receipt?.transactionHash ?? ""
                );
                if (result?.isSuccessful) {
                  await onSuccess(receipt);
                  setNextLevel("success");
                } else {
                  setNextLevel("error");
                  setErrorMessage("Transaction failed and reverted.");
                }
              })();
            }, 5000);
          }
        } else {
          setNextLevel("error");
          setErrorMessage("Transaction failed.");
        }
      })
      .catch((error) => {
        console.error(error);
        setNextLevel("error");
        setErrorMessage(determineError(error.code));
      });
  };

  let value = {
    ethPrice,
    ethProfile,
    getEthProfile,
    payEth,
    updateEthBalance,
  };

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
}

function useWeb3() {
  return React.useContext(Web3Context);
}

export { Web3Provider, useWeb3 };
