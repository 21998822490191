import RequireAuth from "Components/RequireAuth";
import {
  CreateStakePage,
  FeedPage,
  HomePage,
  LoginPage,
  PaymentStatusPage,
  ProfilePage,
  RegisterPage,
  AdminPage,
  VerifyEmailPage,
  AuthActionPage,
  NotificationPage,
  ErrorRegisterPage,
  LeaderboardPage,
} from "Pages";
import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { AuthProvider } from "Utils/authentication";
import { AuthProvider, Web3Provider } from "Utils/context/providers";
import Navbar from "./Components/Navbar";

const protectedPages = [
  { path: "/feed", Component: FeedPage },
  { path: "/profile", Component: ProfilePage },
  { path: "/create", Component: CreateStakePage },
  { path: "/payment-status", Component: PaymentStatusPage },
  { path: "/admin", Component: AdminPage },
  { path: "/verify-email", Component: VerifyEmailPage },
  { path: "/notifications", Component: NotificationPage },
  { path: "/error-register", Component: ErrorRegisterPage },
];

const unprotectedPages = [
  { path: "/", Component: HomePage },
  { path: "/login", Component: LoginPage },
  { path: "/register", Component: RegisterPage },
  { path: "/auth_action", Component: AuthActionPage },
  { path: "/leaderboard", Component: LeaderboardPage },
];

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Web3Provider>
          <Navbar />
          <Routes>
            <Route>
              {unprotectedPages.map(({ path, Component }, i) => (
                <Route key={i} path={path} element={<Component />} />
              ))}
              {protectedPages.map(({ path, Component }, i) => (
                <Route
                  key={i}
                  path={path}
                  element={
                    <RequireAuth>
                      <Component />
                    </RequireAuth>
                  }
                />
              ))}
            </Route>
          </Routes>
        </Web3Provider>
      </AuthProvider>
    </Router>
  );
}
