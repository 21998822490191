import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auth } from "Config/firebase";
import { applyActionCode, confirmPasswordReset } from "firebase/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import { getQueryParam } from "Utils/helpers";
import "./styles/AuthActionPage.scss";

const availableActions = {
  verifyEmail: "verifyEmail",
  resetPassword: "resetPassword",
};

const AuthActionPage = () => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [action, setAction] = useState(null);
  const [code, setCode] = useState(null);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [error, setError] = useState(false);

  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    determineAction();
  }, []);

  useEffect(() => {
    (async () => {
      if (action == availableActions.verifyEmail) {
        await handleEmailVerification();
      }

      // TODO: Handle password reset
    })();
  }, [action]);

  const handleEmailVerification = async () => {
    if (!authContext.user) {
      console.log("redire", { location });
      navigate("/login", {
        replace: true,
        state: { from: location },
      });
      return;
    }
    await applyActionCode(auth, code)
      .then(() => {
        auth.currentUser.reload().then(() => {
          navigate("/feed", { replace: true });
        });
      })
      .catch((err) => {
        setError(
          "Error verifying email.\nTry resending the verifcation email and clicking the link or button in the email."
        );
      });
  };

  const determineAction = () => {
    setIsLoadingAction(true);
    const mode = getQueryParam("mode");
    const code = getQueryParam("oobCode");
    setAction(mode);
    setCode(code);
    setIsLoadingAction(false);
  };

  const handleNewPasswordSubmit = async (event) => {
    event.preventDefault();
    if (isSubmittingPassword) return;

    setIsSubmittingPassword(true);

    if (newPassword !== newPasswordConfirm) alert("Passwords do not match");
    else {
      await confirmPasswordReset(auth, code, newPassword)
        .then(() => {
          setNewPassword("");
          setNewPasswordConfirm("");
          alert("Password updated successfully. You can login now!");
        })
        .catch((err) => {
          alert(`Password update failed ${err?.code && `- ${err?.code}`}`);
        });
    }

    setIsSubmittingPassword(false);
  };

  const isPasswordReset = action == availableActions.resetPassword;

  return (
    <div className="auth-action-page">
      {action ? (
        <div className={isPasswordReset ? "password-reset" : ""}>
          {action == availableActions.verifyEmail &&
            (!error ? (
              <div>Verifying your email, please wait...</div>
            ) : (
              <div>{error}</div>
            ))}
          {isPasswordReset && (
            <form
              className="reset-password-form"
              onSubmit={handleNewPasswordSubmit}
            >
              <legend>Change Password</legend>
              <fieldset>
                <label>
                  <div className="password-label">
                    <div>New Password</div>
                    <FontAwesomeIcon
                      icon={!showPassword ? faEye : faEyeSlash}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  <input
                    name="newPassword"
                    type={showPassword ? "text" : "password"}
                    required
                    pattern="^.{6,}$"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  min 6 characters
                </label>
                <br />

                <label>
                  <div className="password-label">
                    <div>Confirm New Password</div>
                    <FontAwesomeIcon
                      icon={!showPassword ? faEye : faEyeSlash}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  <input
                    name="confirmNewPassword"
                    type={showPassword ? "text" : "password"}
                    required
                    pattern="^.{6,}$"
                    value={newPasswordConfirm}
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                  />
                </label>
                <br />

                <button type="submit">
                  {!isSubmittingPassword ? "Update Password" : "updating..."}
                </button>
              </fieldset>
            </form>
          )}
        </div>
      ) : isLoadingAction ? (
        <div>Loading...</div>
      ) : (
        <div>
          Oops looks like we lost track of the action you want to perform
        </div>
      )}
    </div>
  );
};

export default AuthActionPage;
