import { Link } from "react-router-dom";
import "./styles/HomePage.scss";
import logo_white from "Assets/images/logo_white.svg";
import LogoWithTagWhite from "Assets/images/LogoWithTagWhite";
import LogoWithTag from "Assets/images/LogoWithTag";
import image1 from "Assets/images/image1.svg";
import image2 from "Assets/images/image2.svg";
import image3 from "Assets/images/image3.svg";
import image4 from "Assets/images/image4.svg";
import { durationOptions } from "Utils/variables";
import {
  convertToFixed,
  convertToMoney,
  subscribeToStakePool,
} from "Utils/helpers";
import { useEffect, useState } from "react";
import ethLogo from "Assets/icons/ethLogo.svg";
import ethLogoFull from "Assets/images/eth-logo-full.svg";
import gcpLogoFull from "Assets/images/google_cloud_logo.svg";
import alchemyLogoFull from "Assets/images/alchemy-logo-full.svg";
import metamaskLogoFull from "Assets/images/metamask-logo.svg";
import { useWeb3 } from "Utils/web3";
import twitterLogo from "Assets/icons/twitterIcon.svg";
import discordLogo from "Assets/icons/discordIcon.svg";
// import { setDummyStakes, updateAllDocumentsKey } from "Utils/dangerous";

const HomePage = () => {
  let __durationOptions = durationOptions;
  __durationOptions[0].color = "rgba(108, 99, 255, 0.49)";
  __durationOptions[1].color = "rgba(255, 0, 245, 0.44)";
  __durationOptions[2].color = "rgba(112, 0, 255, 0.47)";

  const [amountInPool, setAmountInPool] = useState(null);

  const web3Context = useWeb3();

  useEffect(() => {
    // setDummyStakes();

    // updateAllDocumentsKey("users", { points: 0, earnings: 0 });

    const poolListenerUnsubscribe = subscribeToStakePool(setAmountInPool);
    return () => {
      poolListenerUnsubscribe();
    };
  }, []);

  return (
    <div className="home-page">
      {/* --- SECTIONS --- */}
      <div className="sections">
        {/* --- SECTION 1 --- */}
        <div id="section1">
          <div>
            <LogoWithTag id="secton1_logo" />
            <div className="message-sections">
              <div className="__header">Make Money The Fun Way</div>
              <div>
                Stake your crypto for a minimum of{" "}
                <b>{durationOptions[0].label}</b> and earn interests ranging
                from <b>{durationOptions[0].interest}%</b> to{" "}
                <b>{durationOptions[2].interest}%</b>
              </div>
            </div>
          </div>
          <img className="side-img" src={image1} alt="" />
        </div>

        {/* --- SECTION 2 --- */}
        <div id="section2">
          <div id="boxes">
            <div className="top-set">
              {__durationOptions
                .filter((o, i) => i != 2)
                .map((option, i) => (
                  <div
                    key={i}
                    className="box"
                    style={{ background: option.color }}
                  >
                    <div>
                      Stake for <b>{option.label}</b> and get
                    </div>
                    <div>{option.interest}% Interest</div>
                  </div>
                ))}
            </div>
            <div
              className="box"
              style={{ background: __durationOptions[2].color }}
            >
              <div>
                Stake for <b>{__durationOptions[2].label}</b>
                <br />
                and get
              </div>
              <div>{__durationOptions[2].interest}% Interest</div>
            </div>
          </div>
          <div className="message-sections">
            <div className="__header">
              Let Your Money Work For You The Fun Way
            </div>
            <div className="__body">
              Create Stake questions and earn mouth-watering interest if nobody
              guesses your answer correctly before the duration runs out.
              <br />
              <br />
              Even if you lose the Stake, you will never lose 100% of your
              capital. You only lose the expected ROI.
            </div>
            <Link to="/create" className="__button">
              Stake Now
            </Link>
          </div>
        </div>

        {/* --- SECTION 3 --- */}
        <div id="section3">
          <div className="message-sections">
            <div className="__header">Earn As You Stake</div>
            <div className="__body">
              Guess the correct answer to random questions created by other
              users and earn free crypto with just the tip of your fingers.
            </div>
            <Link to="/feed" className="__button">
              View Stakes
            </Link>
          </div>
          <img className="side-img" src={image2} alt="" />
        </div>

        {/* --- SECTION 4 --- */}
        <div id="section4">
          <img className="side-img" src={image3} alt="" />
          <div className="message-sections">
            <div className="__header">Refer and Earn</div>
            <div className="__body">
              Share your username with friends and Earn <b>5% commission</b> on
              every stake purchase your referral makes.
            </div>
          </div>
        </div>

        {/* --- SECTION 5 --- */}
        {amountInPool != null && (
          <div id="section5">
            <div className="message-sections">
              <div className="__header">The Stake Pool</div>
              <div className="__body">
                All withdrawals come from the Stake Pool.
                <br />
                You get paid as long as there is money in the Stake Pool.
                <br />
                <br />
                <div id="live-amount">
                  <div>
                    <div
                      className="spinner-grow spinner-grow-sm text-success me-2"
                      role="status"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                    LIVE amount in pool:
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="fw-bold">
                      <div className="d-flex align-items-center justify-content-center">
                        <img src={ethLogo} alt="" />
                        {convertToFixed(amountInPool)}
                      </div>
                      {web3Context.ethPrice && (
                        <div className="ms-1 mt-1" style={{ fontSize: "16px" }}>
                          ≈ $
                          {convertToMoney(
                            (amountInPool * web3Context.ethPrice).toFixed(2)
                          )}{" "}
                          ≈
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img className="side-img" src={image4} alt="" />
          </div>
        )}

        <div style={{ background: "#B6B1FF" }} className="powered mb-0">
          <div
            className="__header fs-3 me-4 flex-grow-0"
            style={{ whiteSpace: "pre" }}
          >
            Powered by
          </div>
          <div className="logos">
            <img src={ethLogoFull} alt="" />
            <img src={gcpLogoFull} alt="" />
            <img src={alchemyLogoFull} alt="" />
            <img src={metamaskLogoFull} alt="" />
          </div>
        </div>
      </div>

      {/* --- FOOTER --- */}
      <div className="footer">
        <div className="inner-container">
          <div>
            <LogoWithTagWhite id="first-logo" />
          </div>

          <div>
            <div>
              <div id="contact-header">Contact</div>
              <div>hello@trystakes.com</div>
              <div className="socialLinks mt-3">
                <a
                  href="https://twitter.com/trystakes"
                  className="me-3"
                  target="_blank"
                >
                  <img
                    src={twitterLogo}
                    alt="twiiter"
                    width="25px"
                    height="25px"
                  />
                </a>
                <a href="https://discord.gg/bNrpp5ybVG" target="_blank">
                  <img
                    src={discordLogo}
                    alt="discord"
                    width="25px"
                    height="25px"
                  />
                </a>
              </div>
            </div>
            <div id="auth-section">
              <Link to="/login">Login</Link>
              <Link id="register-btn" to="/register">
                Register
              </Link>
            </div>
          </div>
          <div>
            <div>
              &copy; {new Date().getFullYear()}{" "}
              <span>
                <img id="last-logo" src={logo_white} alt="" />
              </span>{" "}
              rights reserved
            </div>
            <div>
              <a
                href="https://docs.google.com/document/d/12H9WIbSTh-7igETaqXqc44yPnFZqb8W8g575o59Du5c/edit?usp=sharing"
                target="_blank"
                id="terms-link"
                rel="noreferrer"
              >
                Terms &amp; Conditions
              </a>
              <a
                href="https://docs.google.com/document/d/1e4C149D0JlnnOzp5AH0P3SM97xgmx59hM8DSf_mlk48/edit?usp=sharing"
                target="_blank"
                id="terms-link"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
