import { API_URL } from "Config";
import { useEffect, useState } from "react";
import {
  convertToFixed,
  convertToMoney,
  removeSpecialCharacters,
} from "Utils/helpers";
import { postRequest } from "Utils/requests";
import { durationOptions, roles } from "Utils/variables";
import "../styles/CreateStakePage.scss";
import ethLogo from "Assets/icons/ethLogo.svg";
import { useAuth, useWeb3 } from "Utils/context/hooks";
import Web3WalletInfo from "Components/Web3WalletInfo";
import PaymentProcessModal from "./PaymentProcessModal";

const MIN_AMOUNT = 20;
const PERCENTAGE = 0.05;

const CreateStakePage = () => {
  let __durationOptions = durationOptions;
  __durationOptions[0].color = "#BF0404";
  __durationOptions[1].color = "#2F006B";
  __durationOptions[2].color = "#00830D";

  const authContext = useAuth();
  const web3Context = useWeb3();

  const [selectedDuration, setSelectedDuration] = useState(
    __durationOptions[1].id
  );
  const [amount, setAmount] = useState("");
  const [ethAmount, setEthAmount] = useState("");
  const [totalEthAmount, setTotalEthAmount] = useState("");
  const [serviceCharge, setServiceCharge] = useState("");
  const [totalAmountPayable, setTotalAmountPayable] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [hint1, setHint1] = useState("");
  const [hint2, setHint2] = useState("");
  const [hint3, setHint3] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadLong, setIsLoadLong] = useState(false);
  const [winReturns, setWinReturns] = useState(0);
  const [lossReturns, setLossReturns] = useState(0);
  const [ethWinReturns, setEthWinReturns] = useState(0);
  const [ethLossReturns, setEthLossReturns] = useState(0);

  const [paymentProcessModal, setPaymentProcessModal] = useState(null);
  const [paymentProcessLevel, setPaymentProcessLevel] = useState(1);
  const [paymentProcessError, setPaymentProcessError] = useState(null);

  const ethPrice = web3Context.ethPrice;
  const isInfluencer = authContext.user?.role == roles.influencer;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // alert("Cannot purchase stake coins at this moment. Apologies!");
    // return;
    // ---------------------------------------------------------------

    if (isSubmitting) return;
    setIsSubmitting(true);

    const payment_wallet =
      event.nativeEvent?.submitter?.value ??
      event.nativeEvent.target["6"].value;
    console.log({ payment_wallet });
    let amountForTransaction;
    const fromReferralWallet = payment_wallet == "referral_wallet";

    if (payment_wallet == "processor") amountForTransaction = totalEthAmount;
    if (payment_wallet == "native_wallet") amountForTransaction = ethAmount;
    if (fromReferralWallet) amountForTransaction = amount;

    const processNewStake = async (receipt, fromWeb3) => {
      // send request to server
      console.log("processing new stake");
      if (!fromWeb3) {
        paymentProcessModal?.show();
        setPaymentProcessLevel(2);
      }
      let createStakeData = {
        amount: !fromReferralWallet ? ethAmount : amount,
        fiatAmount: amount,
        duration: selectedDuration,
        question: question,
        answer: answer,
        hints: [hint1, hint2, hint3],
        payment_wallet,
      };
      if (receipt?.transactionHash) {
        createStakeData.txHash = receipt.transactionHash;
      }
      await postRequest(`${API_URL}/stakes-createStake`, createStakeData)
        .then((data) => {
          console.log({ data });
          if (!data.error) {
            setPaymentProcessLevel("success");
          } else {
            setPaymentProcessLevel("error");
            setPaymentProcessError(
              data.error?.message ?? "Something went wrong"
            );
          }
        })
        .catch((error) => {
          console.error(error);
          setPaymentProcessLevel("error");
          setPaymentProcessError("Something went wrong");
        });
    };

    if (amount >= MIN_AMOUNT) {
      if (payment_wallet != "processor") {
        // confirm selected balance has enough balance
        let walletBalance = authContext.user[payment_wallet]; // This is the selected balance

        if (walletBalance < amountForTransaction) {
          alert("Insufficient balance");
          setIsSubmitting(false);
          return;
        }
        await processNewStake();
      } else {
        // collect payment from web3, then process new stake if payment is successful
        if (!web3Context.ethProfile) {
          alert("Please connect a web3 wallet");
          document.getElementById("create-stake-page").scrollIntoView({
            behavior: "smooth",
          });
        } else {
          paymentProcessModal?.show();
          await web3Context.payEth({
            amount: amountForTransaction,
            onSuccess: (receipt) => processNewStake(receipt, true),
            setNextLevel: setPaymentProcessLevel,
            setErrorMessage: setPaymentProcessError,
          });
        }
      }
    } else alert(`Minimum amount is ${convertToMoney(MIN_AMOUNT)}`);

    setIsSubmitting(false);
  };

  const handleAmountChange = (event) => {
    let value = event.target.value;
    if (isNaN(value)) return;
    value = value.replace(".", "");
    setAmount(value);
    setEthAmount(convertToFixed(value / ethPrice));
    let __serviceCharge = Number(value) * PERCENTAGE;
    setServiceCharge(__serviceCharge);
    let __totalAmountPayable = Number(value) + __serviceCharge;
    setTotalAmountPayable(__totalAmountPayable);
    setTotalEthAmount(convertToFixed(__totalAmountPayable / ethPrice));
  };

  useEffect(() => {
    if (!ethPrice) {
      setTimeout(() => {
        setIsLoadLong(true);
      }, 5000);
    }

    // access payment process modal
    setPaymentProcessModal(
      new window.bootstrap.Modal(
        document.getElementById("PaymentProcessModal"),
        {
          keyboard: false,
        }
      )
    );
  }, []);

  useEffect(() => {
    let stakeAmount = Number(ethAmount == "" ? 0 : ethAmount);
    let interestReturns =
      (ethAmount == "" ? 0 : ethAmount) *
      (__durationOptions.find((opt) => opt.id == selectedDuration).interest /
        100);
    let __ethWinReturns = stakeAmount + interestReturns;
    let __ethLossReturns = stakeAmount - interestReturns;

    let __winReturns = __ethWinReturns * ethPrice;
    let __lossReturns = __ethLossReturns * ethPrice;

    setEthWinReturns(convertToFixed(stakeAmount + interestReturns));
    setEthLossReturns(convertToFixed(stakeAmount - interestReturns));
    setWinReturns(convertToFixed(__winReturns));
    setLossReturns(convertToFixed(__lossReturns));
  }, [amount, selectedDuration]);

  const nativeWalletBalance = authContext.user?.native_wallet ?? 0;
  const referralWalletBalance = authContext.user?.referral_wallet ?? 0;

  return (
    <div id="create-stake-page" className="create-stake-page">
      <div className="header">Create New Stake</div>

      <Web3WalletInfo />

      {ethPrice != null ? (
        <form onSubmit={handleSubmit}>
          <label>
            <div>How much do you want to stake?</div>
            <div className="amount-input d-flex justify-content-between inputStyle">
              <div className="d-flex align-items-center flex-grow-1 lh-1">
                <div className="currency has-mobile">$</div>
                <input
                  name="amount"
                  type="text"
                  required
                  placeholder="50"
                  value={amount}
                  onChange={handleAmountChange}
                  className="ignoreInputStyle"
                />
              </div>
              <div className="ethValue web">
                <img src={ethLogo} alt="ethLogo" className="me-1" />
                {ethAmount}
              </div>
            </div>
            <div className="ethValue mobile">
              <img src={ethLogo} alt="ethLogo" className="me-1" />
              {ethAmount}
            </div>
            <span className="mt-1">Minimum: ${convertToMoney(MIN_AMOUNT)}</span>
            <br />
          </label>
          <br />

          <label>
            <div>Enter Stake Question</div>
            <textarea
              name="question"
              placeholder="What can fill a room but takes up no space"
              required
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            ></textarea>
          </label>
          <br />

          <label>
            <div>Enter Answer to Question</div>
            <input
              name="answer"
              type="text"
              required
              placeholder="Light"
              value={answer}
              onChange={(e) => {
                let _val = removeSpecialCharacters(e.target.value);
                setAnswer(_val);
                e.target.value = _val;
              }}
            />
            One word answer only
          </label>
          <br />

          <label className="hints-section">
            <div>Enter your Question Hints</div>
            <div className="note-section">
              <div>Note</div>
              <div>
                Your hints have to correspond appropriately with the question
                and answer or your stake will be rejected.
              </div>
            </div>
            <input
              name="hint1"
              type="text"
              required
              placeholder="You need it during the day"
              value={hint1}
              onChange={(e) => {
                let _val = e.target.value;
                setHint1(_val);
                e.target.value = _val;
              }}
            />
            <input
              name="hint2"
              type="text"
              required
              placeholder="It does not need any medium to travel"
              value={hint2}
              onChange={(e) => {
                let _val = e.target.value;
                setHint2(_val);
                e.target.value = _val;
              }}
            />
            <input
              name="hint3"
              type="text"
              required
              placeholder="It's a type of energy"
              value={hint3}
              onChange={(e) => {
                let _val = e.target.value;
                setHint3(_val);
                e.target.value = _val;
              }}
            />
          </label>
          <br />

          <label className="duration-section">
            <div>How long do you want to stake for?</div>
            <div className="duration-options">
              {__durationOptions.map((option, i) => (
                <div
                  key={option.id}
                  onClick={() => setSelectedDuration(option.value)}
                  className={`${
                    selectedDuration == option.id ? "selected" : ""
                  } ${i == 1 ? "isMiddle" : ""}`}
                >
                  <div>{option.label}</div>
                  <div style={{ color: option.color }}>
                    {option.interest}% Interest
                  </div>
                </div>
              ))}
            </div>
          </label>
          <br />
          {amount !== "" && (
            <>
              <label className="returns-section">
                <div>
                  <div>If you win, your returns will be</div>
                  <div className="returns-label d-flex align-items-center">
                    <img src={ethLogo} className="me-1" alt="" />
                    {ethWinReturns}
                    <span className="ms-1" style={{ fontSize: "14px" }}>
                      ~ ${convertToMoney(winReturns.toFixed(2))}
                    </span>
                  </div>
                </div>
                <div>
                  <div>If you lose, your returns will be</div>
                  <div className="returns-label d-flex align-items-center">
                    <img src={ethLogo} className="me-1" alt="" />
                    {ethLossReturns}
                    <span className="ms-1" style={{ fontSize: "14px" }}>
                      ~ ${convertToMoney(lossReturns.toFixed(2))}
                    </span>
                  </div>
                </div>
              </label>
              <br />
            </>
          )}

          <div className="submit-section">
            <div className="">
              {amount > 0 && (
                <>5% Service charge: ${convertToMoney(String(serviceCharge))}</>
              )}
            </div>
            {amount && (
              <span className="d-flex align-items-center fw-bold">
                ${convertToMoney(totalAmountPayable)} &nbsp;=&nbsp;
                <img
                  src={ethLogo}
                  alt=""
                  style={{ width: "16px", height: "16px" }}
                />
                {totalEthAmount}
              </span>
            )}
            <button type="submit" name="payment_wallet" value="processor">
              <div>
                {!isSubmitting ? `Pay ${totalEthAmount} ETH` : "Processing..."}
              </div>
            </button>
            {!isSubmitting && (
              <div className="other-submit-btns">
                <button
                  type="submit"
                  name="payment_wallet"
                  value="native_wallet"
                  style={!isInfluencer ? {} : { marginRight: 0 }}
                >
                  Pay from profile balance
                  <br />
                  <div>
                    Balance:{" "}
                    <span className="stake-coin">
                      {convertToFixed(nativeWalletBalance)} ETH
                    </span>
                  </div>
                </button>
                {!isInfluencer && (
                  <button
                    type="submit"
                    name="payment_wallet"
                    value="referral_wallet"
                  >
                    Pay from referral earnings
                    <br />
                    <div>
                      Balance: $
                      {convertToMoney(+referralWalletBalance.toFixed(2))}
                    </div>
                  </button>
                )}
              </div>
            )}
          </div>
        </form>
      ) : (
        <div className="text-center mt-5">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          {isLoadLong && (
            <div className="mx-3">
              This seems to be loading longer than usual, try reloading the
              page. Thanks
            </div>
          )}
        </div>
      )}

      <PaymentProcessModal
        amount={amount}
        ethAmount={ethAmount}
        totalEthAmount={totalEthAmount}
        level={paymentProcessLevel}
        errorMessage={paymentProcessError}
        thisModal={paymentProcessModal}
        onClose={async () => {
          setPaymentProcessLevel(1);
          await authContext.refreshUser();
        }}
      />
    </div>
  );
};

export default CreateStakePage;
