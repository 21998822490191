import { convertToFixed, convertToMoney, hideAddress } from "Utils/helpers";
import { useWeb3 } from "Utils/web3";
import ethLogo from "Assets/icons/ethLogo.svg";
import "./styles/Web3WalletInfo.scss";
import { useState } from "react";

const Web3WalletInfo = ({
  onConnectFailure,
  ignoreAlert,
  hideBalance,
  displayAddress,
}) => {
  const [isConnecting, setIsConnecting] = useState(false);

  const web3Context = useWeb3();
  let ethProfile = web3Context.ethProfile;

  const handleConnect = async () => {
    setIsConnecting(true);
    let connectionData = await web3Context.getEthProfile(ignoreAlert);
    if (connectionData?.error) {
      onConnectFailure?.(connectionData.error);
    }
    setIsConnecting(false);
  };

  return (
    <div className="web3-info fw-bold">
      {!ethProfile ? (
        <div className="connect-btn" onClick={handleConnect}>
          {isConnecting ? "Connecting..." : "Connect web3 wallet"}
        </div>
      ) : (
        <div className="connected-info">
          {!hideBalance && (
            <div>
              <div>Balance</div>
              <div className="d-flex align-items-center">
                <img src={ethLogo} />{" "}
                <span className="ms-1 d-flex align-items-center">
                  {convertToFixed(ethProfile.eth_balance)}
                  {web3Context.ethPrice && (
                    <span className="ms-1" style={{ fontSize: "14px" }}>
                      ~ $
                      {convertToMoney(
                        Number(
                          Number(ethProfile.eth_balance) * web3Context.ethPrice
                        ).toFixed(2)
                      )}
                    </span>
                  )}
                </span>
              </div>
            </div>
          )}
          <div className="isAddress">
            <div>Address</div>
            <div className="fw-bold">
              {!displayAddress
                ? hideAddress(ethProfile.eth_address)
                : ethProfile.eth_address}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Web3WalletInfo;
