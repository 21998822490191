import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auth } from "Config/firebase";
import { updatePassword } from "firebase/auth";
import { useState } from "react";
import { useAuth } from "Utils/authentication";
import "./styles/UserInfo.scss";

// create a UserInfo function component that returns a div to show the users info from authContext.user
// the component should be able to update the users info
// the component should be able to update the users password

const UserInfo = () => {
  const authContext = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);

    if (newPassword !== newPasswordConfirm) alert("Passwords do not match");
    else {
      await updatePassword(auth.currentUser, newPassword)
        .then(() => {
          setNewPassword("");
          setNewPasswordConfirm("");
          alert("Password updated successfully");
        })
        .catch(() => alert("Password update failed"));
    }

    setIsSubmitting(false);
  };

  const userData = authContext?.user;

  return (
    <div className="user-info">
      <form onSubmit={handleSubmit}>
        <legend>Change Password</legend>
        <fieldset>
          <label>
            <div className="password-label">
              <div>New Password</div>
              <FontAwesomeIcon
                icon={!showPassword ? faEye : faEyeSlash}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <input
              name="newPassword"
              type={showPassword ? "text" : "password"}
              required
              pattern="^.{6,}$"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            min 6 characters
          </label>
          <br />

          <label>
            <div className="password-label">
              <div>Confirm New Password</div>
              <FontAwesomeIcon
                icon={!showPassword ? faEye : faEyeSlash}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <input
              name="confirmNewPassword"
              type={showPassword ? "text" : "password"}
              required
              pattern="^.{6,}$"
              value={newPasswordConfirm}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
            />
          </label>
          <br />

          <button type="submit">
            {!isSubmitting ? "Update Password" : "updating..."}
          </button>
        </fieldset>
      </form>

      <div className="disabled-form">
        <legend>
          <div>
            Account created on {userData?.createdAt?.toDate?.()?.toDateString()}
            , {userData?.createdAt?.toDate?.()?.toLocaleTimeString()}
          </div>
          <span>
            You would need to contact support to update the follwing
            information.
          </span>
        </legend>

        <fieldset disabled>
          <label>
            <div>First name</div>
            <input
              name="firstName"
              type="text"
              required
              disabled
              value={userData?.firstName}
            />
          </label>
          <br />

          <label>
            <div>Last name</div>
            <input
              name="lastName"
              type="text"
              required
              disabled
              value={userData?.lastName}
            />
          </label>
          <br />

          <label>
            <div>Username</div>
            <input
              name="username"
              type="text"
              pattern=".{4,15}"
              required
              disabled
              value={userData?.username}
            />
          </label>
          <br />

          <label>
            <div>Email</div>{" "}
            <input
              name="email"
              type="email"
              required
              disabled
              value={userData?.email}
            />
          </label>
          <br />

          <label>
            <div>Date Of Birth</div>{" "}
            <input
              name="dob"
              type="date"
              required
              disabled
              value={userData?.dob}
            />
          </label>
          <br />

          <label>
            <div>Phone number</div>{" "}
            <input
              name="phone"
              type="tel"
              required
              disabled
              value={userData?.phone}
            />
          </label>
          <br />

          {userData.referredBy && (
            <label>
              <div>Referred by</div>
              <input
                name="referral"
                type="text"
                pattern="^.{4,}$"
                disabled
                value={userData.referredBy}
              />
            </label>
          )}
        </fieldset>
      </div>
    </div>
  );
};

export default UserInfo;
