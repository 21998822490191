import { auth } from "Config/firebase";
import { sendEmailVerification } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import { usePrevious } from "Utils/hooks";
import "./styles/VerifyEmailPage.scss";

const VerifyEmailPage = () => {
  const authContext = useAuth();
  const navigate = useNavigate();

  const prevEmailVerified = usePrevious(authContext?.user?.emailVerified);

  useEffect(() => {
    console.log({ user: authContext.user });
    if (!prevEmailVerified && authContext.user?.emailVerified) {
      navigate("/feed", { replace: true });
    }
  }, [authContext.user?.emailVerified]);

  const resendVerificationEmail = async () => {
    sendEmailVerification(auth.currentUser);
  };

  return (
    <div className="verify-email-page">
      <div>
        <div className="title">Verify your email</div>
        <div className="message">
          Hey {authContext?.user?.firstName ?? "there"}, we have sent a
          verification link to your email.
          <br />
          Please click on the link to verify your email.
        </div>
        <div className="suggestion">
          If you can't find the email in your inbox you can check you Spam
          folder. Thanks!!
        </div>

        <button onClick={resendVerificationEmail}>
          Resend verification email
        </button>

        <div className="help">
          Questions? Email us at <span>hello@trystakes.com</span>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
