import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import "./styles/ProfilePage.scss";
import UserInfo from "./SubPages/UserInfo";
import UserStakes from "./SubPages/UserStakes";
import UserWallet from "./SubPages/UserWallet";

const sections = [
  { id: "wallet", title: "My Balance", Component: UserWallet },
  { id: "stakes", title: "My Stakes", Component: UserStakes },
  { id: "info", title: "My Info", Component: UserInfo },
];

const ProfilePage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    auth.signout(() => {
      navigate("/login", { replace: true });
    });
  };

  const [activeSection, setActiveSection] = useState(sections[0].id);
  const isAdmin = auth.user?.isAdmin;
  // const isAdmin = false;

  const ActiveSectionComponent = sections.find(
    (section) => section.id === activeSection
  ).Component;

  return (
    <div className="profile-page">
      <div>
        <div>
          <div>{auth?.user?.username ?? "My Profile"}</div>
          <a href="#" onClick={handleLogout} style={{ display: "block" }}>
            Logout
          </a>
        </div>
        {!isAdmin && (
          <Link to="/create" className="add-btn">
            New Stake
          </Link>
        )}
      </div>

      {!isAdmin && (
        <div className="sections">
          <div>
            {sections.map((section, i) => (
              <div
                key={i}
                onClick={() => setActiveSection(section.id)}
                className={`${activeSection == section.id && "isSelected"}`}
              >
                <div>{section.title}</div>
              </div>
            ))}
          </div>
          <div className="section">
            <ActiveSectionComponent />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
