import { useState } from "react";
import "../styles/LeaderboardPage.scss";

import LeaderboardTable from "./LeaderboardTable";
import { leaderboardTypes } from "Utils/variables";
import LeaderboardInfo from "./LeaderboardInfo";
import { useAuth } from "Utils/authentication";
import { Link } from "react-router-dom";

const LeaderboardPage = () => {
  const [boardType, setBoardType] = useState(leaderboardTypes.pointers.label);

  const auth = useAuth();

  return (
    <div className="leaderboard-page p-auto">
      <h1 className="text-center">
        ⭐ TOP {leaderboardTypes[boardType].label.toUpperCase()}
      </h1>

      <div className="leaderboard-options">
        <button
          className={`btn shadow-none ${
            boardType === leaderboardTypes.earners.label ? "btn-lg" : ""
          }`}
          onClick={() => setBoardType(leaderboardTypes.earners.label)}
        >
          Earners
        </button>
        <button
          className={`btn shadow-none ${
            boardType === leaderboardTypes.pointers.label ? "btn-lg" : ""
          }`}
          onClick={() => setBoardType(leaderboardTypes.pointers.label)}
        >
          Pointers
        </button>
        <button
          className={`btn shadow-none ${
            boardType === leaderboardTypes.referrals.label ? "btn-lg" : ""
          }`}
          onClick={() => setBoardType(leaderboardTypes.referrals.label)}
        >
          Referrals
        </button>
      </div>

      <LeaderboardInfo boardType={boardType} />

      <LeaderboardTable boardType={boardType} />

      {!auth.user && (
        <div className="text-center">
          <p style={{ color: "#3c06cd" }}>
            You need to be logged in to see the leaderboard.
          </p>
          <Link
            to="/login"
            className="btn"
            style={{ backgroundColor: "#7000ff", color: "#fff" }}
          >
            Login
          </Link>
        </div>
      )}
    </div>
  );
};

export default LeaderboardPage;
