import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_URL } from "Config";
import { useEffect, useState } from "react";
import { useAuth } from "Utils/authentication";
import { convertToMoney, getDaysLeft, subscribeToStake } from "Utils/helpers";
import { postRequest } from "Utils/requests";
import { useWeb3 } from "Utils/web3";
import "./styles/StakeItem.scss";
import ethLogo from "Assets/icons/ethLogo.svg";

const StakeItem = ({ stake, handleOnSuccess }) => {
  const auth = useAuth();
  const web3Context = useWeb3();
  let thisUserID = auth.user?.id;
  const [isAttempting, setIsAttempting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionResult, setSubmissionResult] = useState(null);
  const [error, setError] = useState(null);

  const [attemptCount, setAttemptCount] = useState(null);

  const toggleAttempt = () => setIsAttempting(!isAttempting);

  useEffect(() => {
    const poolListenerUnsubscribe = subscribeToStake(stake.id, (data) => {
      setAttemptCount(data.attemptCount);
    });
    return () => {
      poolListenerUnsubscribe();
    };
  }, []);

  const handleSubmitAttempt = async (event) => {
    event.preventDefault();
    if (isSubmitting || submissionResult?.isCorrect) return;
    // if (stake.userID == thisUserID || !thisUserID) {
    //   alert("You cannot attempt your own stake!");
    //   return;
    // }
    setError(null);
    setIsSubmitting(true);
    let formData = new FormData(event.currentTarget);
    let attempt = formData.get(`${stake.id}_input`);

    let isCorrect;

    await postRequest(`${API_URL}/stakes-attemptStake`, {
      attempt,
      stakeID: stake.id,
    })
      .then((data) => {
        console.log(data);
        if (data.error) {
          setError(true);
        } else {
          setSubmissionResult({ isCorrect: data.data.isCorrect, attempt });
          isCorrect = data.data.isCorrect;
        }
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      });

    setIsSubmitting(false);
    if (isCorrect) {
      handleOnSuccess?.(stake.id);
    }
  };

  const daysLeft = getDaysLeft(stake.expiration);

  const isExpired = stake.expiration < new Date().toISOString();

  const ethPrice = web3Context.ethPrice;

  return isExpired ? null : (
    <div
      className="stake-item"
      style={
        isExpired
          ? {
              opacity: "0.5",
              filter: "grayscale(100%)",
            }
          : {}
      }
    >
      <div className="header">
        <div className="profile mobile">
          <img src={stake.userPhoto} alt="" />
          <div>{stake.username}</div>
        </div>
        <div className="question">{stake.question}?</div>
        <div className="profile web">
          <img src={stake.userPhoto} alt="" />
          <div>{stake.username}</div>
        </div>
      </div>

      <div className="body">
        <div>
          <div>
            <div>
              <FontAwesomeIcon icon={faLightbulb} />
              <div>Hints</div>
            </div>
            <ul>
              {stake.hints.map((hint, i) => (
                <li key={i}>{hint}</li>
              ))}
            </ul>
          </div>
          <div className="d-flex align-items-center">
            <img src={ethLogo} alt="" />
            <div>
              <div>Reward</div>
              <span>{stake.reward}</span>
              <span>
                {ethPrice && (
                  <span style={{ fontSize: "14px" }}>
                    ${convertToMoney((stake.reward * ethPrice).toFixed(2))}
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="info">
          <div>
            {daysLeft} day{daysLeft != 1 ? "s" : ""} left
          </div>
          {attemptCount > 0 && <div>{attemptCount} attempts</div>}
        </div>
        <div
          className={`attempt-btn ${isAttempting && "isOpen"}`}
          onClick={!isExpired ? toggleAttempt : () => {}}
          style={!isExpired ? {} : { cursor: "initial" }}
        >
          Attempt
        </div>
      </div>

      {isAttempting && (
        <div className="attempt-section">
          <form onSubmit={handleSubmitAttempt}>
            <input
              type="text"
              name={`${stake.id}_input`}
              required
              placeholder="Enter your guess"
            />
            <button type="submit">Submit</button>
          </form>
          {(submissionResult || isSubmitting || error) && (
            <div className="submission-result">
              {isSubmitting ? (
                <div>submitting...</div>
              ) : error ? (
                <div>An error occurred. 😪</div>
              ) : (
                <div className="result">
                  {submissionResult?.isCorrect ? (
                    <>
                      <div>
                        <FontAwesomeIcon icon={faCheckCircle} color="#04BF17" />
                        <div>Correct!!! 🥳 🙌 🎉</div>
                      </div>
                      <span>
                        The reward will be credited to your profile balance
                        (minus 40% commission fee).
                      </span>
                    </>
                  ) : (
                    <>
                      <div>
                        <FontAwesomeIcon icon={faTimesCircle} color="#BF0404" />
                        <div>Incorrect!!! 😭</div>
                      </div>
                      <span>Please try again.</span>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StakeItem;
