import { db } from "Config/firebase";
import { collection, getDocs, limit, query, where } from "firebase/firestore";

export const verifyReferral = async ({ referral }) => {
  const verificationQuery = query(
    collection(db, "users"),
    where("username", "==", referral),
    limit(1)
  );
  const users = await getDocs(verificationQuery).catch((err) => {
    console.error(err);
    throw new Error("Could not verify referral");
  });
  if (users.empty) {
    throw new Error(
      "The referral does not exist. Please fix the referral name or remove it from the form to continue."
    );
  } else return true;
};

export const verifyUsername = async ({ username }) => {
  // check users collection for username
  const verificationQuery = query(
    collection(db, "users"),
    where("username", "==", username),
    limit(1)
  );
  const users = await getDocs(verificationQuery).catch((err) => {
    console.error(err);
    throw new Error("Could not verify username");
  });
  if (users.empty) {
    return true;
  } else throw new Error("Username already exists");
};
