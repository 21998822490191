import logo from "./logo_white.svg";
import logoTag from "./new_logoTag_white.svg";
// import logoTag from "./logoTag_white.svg";

const LogoWithTagWhite = ({ id = "", className = "", style = {} }) => {
  return (
    <div
      id={id}
      className={className}
      style={{ width: "445.44px", height: "181.43px", ...style }}
    >
      <img src={logo} style={{ width: "100%", height: "80%" }} alt="" />
      <img src={logoTag} style={{ width: "100%", height: "20%" }} alt="" />
    </div>
  );
};

export default LogoWithTagWhite;
