import { db } from "Config/firebase";
import { doc, onSnapshot } from "firebase/firestore";

export const getQueryParam = (param) => {
  let params = new URL(document.location).searchParams;
  return params.get(param);
};

export const convertToMoney = (amount) => {
  return String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getDaysLeft = (date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const today = new Date();
  const expiryDate = new Date(date);
  const daysLeft = Math.round(
    (expiryDate.getTime() - today.getTime()) / oneDay
  );
  return daysLeft;
};

export const removeSpecialCharacters = (str) => {
  return str.replace(/[^a-zA-Z0-9]/g, "");
};

export const subscribeToStakePool = (onSnapshotChange) => {
  const poolListener = onSnapshot(doc(db, "system/pool"), (snapshot) => {
    if (snapshot.data()) {
      onSnapshotChange(snapshot.data().balance);
    }
  });

  return poolListener;
};

export const subscribeToEthPrice = (onSnapshotChange) => {
  const priceListener = onSnapshot(doc(db, "system/eth_price"), (snapshot) => {
    if (snapshot.data()) {
      onSnapshotChange(snapshot.data().last_price);
    }
  });

  return priceListener;
};

export const subscribeToStake = (stakeID, onSnapshotChange) => {
  const stakeListener = onSnapshot(doc(db, `stakes/${stakeID}`), (snapshot) => {
    if (snapshot.data()) {
      onSnapshotChange(snapshot.data());
    }
  });

  return stakeListener;
};

export const hideAddress = (address) => {
  return address.substr(0, 5) + "..." + address.substr(address.length - 7);
};

export const determineError = (errorCode) => {
  switch (errorCode) {
    case 4001:
    case -32603:
      return "You rejected the request.";
    case 4100:
      return "Oops, Not authorized.";
    case 4200:
      return "Oops, Unsupported method.";
    case 4900:
      return "Oops, Looks like you're disconnected.";
    case 4901:
      return "Oops, Looks like your network was changed.";
    default:
      return "An error occurred";
  }
};

export const convertToFixed = (num) => {
  return +Number(num).toFixed(4);
};
