import { API_URL } from "Config";
import { db } from "Config/firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { postRequest } from "Utils/requests";
import "../styles/AdminPage.scss";

const accept = "accept";
const reject = "reject";

const StakeReview = () => {
  const [pendingStakes, setPendingStakes] = useState([]);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [decision, setDecision] = useState(null);
  const [reason, setReason] = useState("");

  useEffect(() => {
    fetchNextStake();
  }, []);

  const fetchNextStake = async () => {
    // get 1 stake from pending stakes collection sorted by oldest
    setError(null);
    if (isFetching) return;
    setIsFetching(true);
    const reqQuery = query(
      collection(db, "pendingStakes"),
      where("paymentComplete", "==", true),
      orderBy("createdAt", "asc"),
      limit(1)
    );
    await getDocs(reqQuery)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          setPendingStakes([]);
        } else {
          const stake = {
            ...querySnapshot.docs[0].data(),
            id: querySnapshot.docs[0].id,
          };
          setPendingStakes([stake]);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      });
    setIsFetching(false);
  };

  const handleReview = async (id) => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    let submitData = { id, approved: decision == accept };
    if (reason?.length > 0) {
      submitData.reason = reason;
    }

    await postRequest(`${API_URL}/stakes-reviewStake`, submitData)
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          fetchNextStake();
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err?.toString() ?? "An error occurred. Check logs!");
      });
    setIsSubmitting(false);
    resetReview();
  };

  const resetReview = () => {
    setReason("");
    setDecision(null);
  };

  return (
    <>
      {isFetching && (
        <div className="text-center mb-3">
          <div>Loading...</div>
        </div>
      )}

      {error ? (
        <div>
          <div>An error occured</div>
          <div>Error: {error.message}</div>
        </div>
      ) : isSubmitting ? (
        <div className="text-center">
          <span className="text-capitalize">{decision}</span>ing the stake and
          loading next...
        </div>
      ) : (
        <div>
          {pendingStakes.map((stake, i) => (
            <div key={i}>
              {decision && (
                <>
                  <div className="text-center fs-3">
                    Are you sure you want to{" "}
                    <span className="text-decoration-underline fw-bold">
                      {decision}
                    </span>{" "}
                    this stake?
                  </div>
                  {decision == reject && (
                    <div className="my-2 d-flex align-items-center">
                      <div>Enter Short Reason?</div>
                      <input
                        type="text"
                        className="form-control"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="buttons mb-5">
                    <div onClick={() => handleReview(stake.id)}>Yes</div>
                    <div onClick={resetReview}>No</div>
                  </div>
                </>
              )}

              <div className="stake-details">
                <div>
                  <div>Created on </div>
                  <div>
                    {new Date(stake.createdAt.toDate()).toLocaleString()}
                  </div>
                </div>
                <div>
                  <div>Question: </div>
                  <div>{stake.question}</div>
                </div>
                <div>
                  <div>Answer: </div>
                  <div>{stake.answer}</div>
                </div>
                <div className="hints">
                  <div>Hints: </div>
                  <ul>
                    {stake.hints.map((hint, i) => (
                      <li key={i}>{hint}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <div>Amount: </div>
                  <div>
                    ETH {stake.amount} ~ ${stake.fiatAmount}
                  </div>
                </div>
                <div>
                  <div>Duration: </div>
                  <div>{stake.duration} days</div>
                </div>
              </div>
              {!decision && (
                <div className="buttons">
                  <div onClick={() => setDecision(accept)}>Accept</div>
                  <div onClick={() => setDecision(reject)}>Reject</div>
                </div>
              )}
            </div>
          ))}
          {pendingStakes.length == 0 && (
            <>
              <div>No pending stakes to review</div>
              <div
                className="text-decoration-underline mt-3"
                onClick={fetchNextStake}
              >
                Reload
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default StakeReview;
