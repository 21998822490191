import StakeItem from "Components/StakeItem";
import { db } from "Config/firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import { convertToFixed, subscribeToStakePool } from "Utils/helpers";
import { usePrevious } from "Utils/hooks";
import "./styles/FeedPage.scss";
import ethLogo from "Assets/icons/ethLogo.svg";

const expiring = "expiring";
const newest = "newest";
const hightest = "hightest";

let filterTypes = [expiring, newest];

const FeedPage = () => {
  const auth = useAuth();
  const [isFetching, setIsFetching] = useState(false);
  let [nextQuery, setNextQuery] = useState(null);
  let [requestedStakes, setRequestedStakes] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [activeFilter, setActiveFilter] = useState(filterTypes[0]);

  const [amountInPool, setAmountInPool] = useState(null);

  useEffect(() => {
    const poolListenerUnsubscribe = subscribeToStakePool(setAmountInPool);
    return () => {
      poolListenerUnsubscribe();
    };
  }, []);

  const prev_authUser = usePrevious(auth.user);
  useEffect(() => {
    if (!prev_authUser && auth.user) {
      (async () => {
        await fetchRequestedStakes();
      })();
    }
  }, [auth.user]);

  const prev_activeFiler = usePrevious(activeFilter);
  useEffect(() => {
    if (prev_activeFiler && prev_activeFiler !== activeFilter && auth.user) {
      setRequestedStakes([]);
      setNextQuery(null);
      setIsEmpty(false);
      (async () => {
        await fetchRequestedStakes(true);
      })();
    }
  }, [activeFilter]);

  const fetchRequestedStakes = async (ignoreNextQuery) => {
    if (isFetching) return;
    setIsFetching(true);

    if (ignoreNextQuery) {
      nextQuery = null;
      requestedStakes = [];
    }

    const commonQueryParams = [
      collection(db, "stakes"),
      where("expiration", ">", new Date().toISOString()),
    ];

    const expiringSoonQueryParams = [
      collection(db, "stakes"),
      where("expiration", ">", new Date().toISOString()),
      orderBy("expiration", "asc"),
    ];

    const newStakesQueryParams = [
      collection(db, "stakes"),
      orderBy("createdAt", "desc"),
    ];

    const hightestRewardQueryParams = [
      collection(db, "stakes"),
      orderBy("reward", "desc"),
    ];

    // fetch stakes where expiration hasn't been met

    // order by closest expiration
    const expiringSoonQuery = query(...expiringSoonQueryParams, limit(10));

    // order by newest stakes
    const newStakesQuery = query(...newStakesQueryParams, limit(10));

    // order by highest reward
    const hightestRewardQuery = query(...hightestRewardQueryParams, limit(10));

    const reqQuery =
      nextQuery ??
      (activeFilter == expiring
        ? expiringSoonQuery
        : activeFilter == hightest
        ? hightestRewardQuery
        : newStakesQuery);

    await getDocs(reqQuery)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          setIsEmpty(true);
          return;
        }

        let limitUsed = nextQuery ? 5 : 10;
        if (querySnapshot.size < limitUsed) {
          setIsEmpty(true);
        }

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const __nextQuery =
          activeFilter == expiring
            ? query(
                ...expiringSoonQueryParams,
                limit(5),
                startAfter(lastVisible)
              )
            : activeFilter == hightest
            ? query(
                ...hightestRewardQueryParams,
                limit(5),
                startAfter(lastVisible)
              )
            : query(...newStakesQueryParams, limit(5), startAfter(lastVisible));

        setNextQuery(__nextQuery);
        const fetched_stakes = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setRequestedStakes([...requestedStakes, ...fetched_stakes]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const filterCorrectStake = (id) => {
    const filteredStakes = requestedStakes.filter((stake) => stake.id !== id);
    setTimeout(() => setRequestedStakes([...filteredStakes]), 7000);
  };

  return (
    <div className="feed-page">
      <div className="header">
        <div>
          <div>Hey {auth.user?.firstName ?? "User"},</div>
          <div>Start Winning!</div>
        </div>
        <Link to="/create">
          <div>New Stake</div>
        </Link>
      </div>

      <div className="body">
        <div className="body-header-info">
          {amountInPool != null && (
            <div id="stake-pool">
              <div>
                <div
                  className="spinner-grow spinner-grow-sm text-success me-2"
                  role="status"
                >
                  <span className="visually-hidden">LIVE</span>
                </div>
                <div>
                  Stake pool<span>:</span>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <img src={ethLogo} alt="" width="22px" height="22px" />
                <span>{convertToFixed(amountInPool)}</span>
              </div>
            </div>
          )}
          <div className={`filter ${amountInPool == null && "no-pool"}`}>
            <div>
              Sort by<span>:</span>
            </div>
            <select
              value={activeFilter}
              onChange={(e) => setActiveFilter(e.currentTarget.value)}
            >
              <option value={expiring}>Expiring soon</option>
              <option value={newest}>Newest</option>
              <option value={hightest}>Highest reward</option>
            </select>
          </div>
        </div>

        <div className="stakes">
          {requestedStakes.map((stake) => (
            <StakeItem
              key={stake.id}
              stake={stake}
              handleOnSuccess={filterCorrectStake}
            />
          ))}

          <div className="loader">
            {requestedStakes.length == 0 && !isFetching ? (
              <div>
                <div>No active stakes at the moment</div>
                <div
                  className="text-center mt-3 load-btn text-decoration-underline"
                  onClick={() => {
                    fetchRequestedStakes();
                  }}
                >
                  Reload
                </div>
              </div>
            ) : isFetching ? (
              <div>Loading...</div>
            ) : !isEmpty ? (
              <div
                className="load-btn"
                onClick={() => {
                  fetchRequestedStakes();
                }}
              >
                Load more
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedPage;
