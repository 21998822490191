import logo from "Assets/images/logo.svg";
import metamaskLogo from "Assets/images/metamask-logo.svg";
import ethFullLogo from "Assets/images/eth-logo-full.svg";
import { no_network, no_web3 } from "Utils/variables";

const modalID = "Web3ConnectModal";

const Web3ConnectModal = ({ thisModal, purpose }) => {
  const handleCloseModal = () => {
    thisModal?.hide();
  };

  const installPurpose = purpose === no_web3;
  const networkPurpose = purpose === no_network;

  return (
    <div
      className="modal fade"
      id={modalID}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content web3ConnectModal">
          <div className="d-flex align-items-end justify-content-center logos">
            <img src={logo} />
            {(installPurpose || networkPurpose) && (
              <>
                <span className="mx-3">X</span>
                <img src={installPurpose ? metamaskLogo : ethFullLogo} />
              </>
            )}
          </div>

          {installPurpose && (
            <>
              <div className="fw-bold mb-3 text-center">
                Looks like you do not have a web3 wallet installed.
              </div>

              <div>
                You need to have a web3 wallet browser extension installed on
                your computer.
                <br />
                Or if you are using a mobile device, you need a web3 wallet
                mobile browser.
                <br />
                <span>
                  Examples include: Metamask, Coinbase wallet, Trust wallet, etc
                </span>
              </div>

              <ul className="mt-3">
                <li>
                  <b>Install Metamask:</b>{" "}
                  <a href="https://metamask.io/" target="_blank">
                    https://metamask.io/
                  </a>
                </li>
                <li>
                  <b>How to use Metamask:</b>{" "}
                  <a href="https://metamask.io/faqs/" target="_blank">
                    https://metamask.io/faqs/
                  </a>
                </li>
              </ul>
            </>
          )}

          {networkPurpose && (
            <>
              <div className="fw-bold mb-3 text-center">
                Looks like you're on a wrong network.
              </div>

              <div>
                You need to be on <b>Ethereum mainnet</b> to use this app.
              </div>

              <a
                href="https://autofarm.gitbook.io/autofarm-network/how-tos/defi-beginners-guide/switching-networks-on-metamask"
                target="_blank"
                className="mt-3 mb-5"
              >
                How to switch Metamask network
              </a>
            </>
          )}

          <div>
            Please reach out to{" "}
            <span style={{ color: "#7000ff" }}>hello@trystakes.com</span> if you
            need assistance getting your wallet connected.
          </div>

          <div className="text-center">
            <div
              className="fw-bold mt-3"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Web3ConnectModal;
