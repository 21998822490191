import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "Utils/authentication";
import "./styles/AdminPage.scss";
import StakeReview from "./SubPages/StakeReview";
import WithdrawalReview from "./SubPages/WithdrawalReview";

const reviewTypes = [
  { id: "stakes", label: "Stake" },
  // { id: "withdrawals", label: "Withdrawal" },
];
const AdminPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [activeMission, setActiveMission] = useState(reviewTypes[0].id);

  useEffect(() => {
    if (!auth.user?.isAdmin) {
      navigate("/feed", { replace: true });
    }
  }, [auth.user]);

  return (
    <div className="admin-page">
      <div>
        <div>Welcome Admin</div>
      </div>

      <div className="sub-nav">
        {reviewTypes.map((reviewType, i) => (
          <div
            key={reviewType.id}
            onClick={() => {
              setActiveMission(reviewType.id);
            }}
            className={`${reviewType.id == activeMission ? "selected" : ""}`}
          >
            {reviewType.label}s
          </div>
        ))}
      </div>

      <div>
        {activeMission == "stakes" && <StakeReview />}
        {activeMission == "withdrawals" && <WithdrawalReview />}
      </div>
    </div>
  );
};

export default AdminPage;
