import { API_URL } from "Config";
import { auth, db, storage } from "Config/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postRequest } from "Utils/requests";
import { useWeb3 } from "Utils/web3";
import { verifyReferral, verifyUsername } from "./registration";

let AuthContext = React.createContext({
  user: null,
  register: async (newUser, callback) => {},
  signin: async (newUser, callback) => {},
  signout: async (callback) => {},
  handleSetUser: (userData, callback) => {},
  refreshUser: async (callback) => {},
});

function AuthProvider({ children }) {
  let [user, setUser] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  let [unsubscribe, setUnsubscribe] = React.useState(null);

  const web3Context = useWeb3();

  React.useEffect(() => {
    if (unsubscribe) {
      unsubscribe();
    }

    const updatedUnsubscribe = auth.onAuthStateChanged((newUser) => {
      if (newUser) {
        handleSetUser(newUser);
      } else {
        signout(() => {
          if (location.pathname != "/") {
            const redirectSafePaths = [
              "/register",
              "/auth_action?mode=resetPassword",
              "/leaderboard",
            ];

            const locationPathAndQuery = location.pathname + location.search;
            const safePath = redirectSafePaths.filter((path) =>
              locationPathAndQuery.includes(path)
            );
            const isSafe = safePath.length > 0;
            console.log({ isSafe, safePath });
            navigate(isSafe ? locationPathAndQuery : "/login", {
              replace: true,
            });
          }
        });
      }
    });
    setUnsubscribe(updatedUnsubscribe);
  }, [location.pathname]);

  const handleSetUser = async (userData, callback) => {
    // check if is admin
    let isAdmin;
    await auth.currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        isAdmin = !!idTokenResult.claims.admin;
      })
      .catch((err) => {
        console.error(err);
      });

    // fetch user info from firestore database
    await getDoc(doc(db, `users/${userData.uid}`))
      .then((user) => {
        setUser({
          ...user.data(),
          id: user.id,
          emailVerified: userData.emailVerified,
          isAdmin,
        });
        callback?.(isAdmin);
      })
      .catch((err) => {
        console.error(err);
      });

    // update eth balance
    await web3Context.updateEthBalance();
  };

  const refreshUser = async (callback) => {
    await auth.currentUser
      .reload()
      .then(() => {
        handleSetUser(auth.currentUser, callback);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let register = async (newUser, callback) => {
    return await createUserWithEmailAndPassword(
      auth,
      newUser?.email,
      newUser?.password
    )
      .then(async (userCredential) => {
        let uploadResult;
        try {
          const { username } = newUser;
          const usernameIsVerified = await verifyUsername({ username });

          if (!usernameIsVerified) {
            throw new Error("Could not verify user information");
          }

          // Creating the user profile document starts from here
          const { photo } = newUser;

          // Upload profile pic to firebase storage
          const profilePicName = photo.name;
          const profilePicExtension = profilePicName?.substring(
            profilePicName.lastIndexOf(".") + 1,
            profilePicName.length
          );
          const profilePictureRef = ref(
            storage,
            `profilePictures/${userCredential.user.uid}.${profilePicExtension}`
          );

          uploadResult = await uploadBytes(profilePictureRef, photo);
          let photoURL = await getDownloadURL(profilePictureRef);

          const dbData = {
            firstName: newUser?.firstName,
            lastName: newUser?.lastName,
            email: newUser?.email,
            username: newUser?.username,
            dob: newUser?.dob,
            phone: newUser?.phone,
            eth_wallet: newUser?.eth_wallet,
            photo: photoURL,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          };

          if (newUser.referral) {
            const referralIsVerified = await verifyReferral({
              referral: newUser.referral,
            });
            if (referralIsVerified) dbData.referredBy = newUser.referral;
            else throw new Error("Could not verify referral");
          }

          // Save user info to firebase database
          await setDoc(doc(db, `users/${userCredential.user.uid}`), dbData);
          await handleSetUser(userCredential.user, callback);
          await updateProfile(auth.currentUser, {
            displayName: newUser?.firstName,
            photoUrl: photoURL,
          });
          sendEmailVerification(userCredential.user);
          callback();
        } catch (error) {
          // if an error occurs while saving the user info, delete the user from firebase
          await userCredential.user.delete();
          if (uploadResult?.ref) {
            await postRequest(
              `${API_URL}/misc-deleteProfilePic`,
              {
                ref: uploadResult.ref.fullPath,
              },
              true
            );
          }
          console.error(error);
          alert(error?.message ?? "An error occured. Please try again.");
        }
      })
      .catch((error) => {
        alert(error.code);
        console.error(error);
      });
  };

  let signin = async (newUser, callback) => {
    return await signInWithEmailAndPassword(
      auth,
      newUser?.email,
      newUser?.password
    )
      .then(async (userCredential) => {
        await handleSetUser(userCredential.user, callback);
      })
      .catch((error) => {
        alert(error.code);
        console.error(error);
      });
  };

  let signout = async (callback) => {
    return await auth.signOut().then(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, register, signin, signout, handleSetUser, refreshUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}
export { AuthProvider, useAuth };
