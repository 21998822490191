import "./styles/NotificationItem.scss";

const NotificationItem = ({ item }) => {
  let createdAt = item.createdAt.toDate();

  return (
    <div className="notification-item">
      <div className="header">
        <div className="title">{item.type?.replace("_", " ")}</div>
        <div className="date">
          {createdAt.toDateString() + ", " + createdAt.toLocaleTimeString()}
        </div>
      </div>
      <div className="message">{item.message}</div>
    </div>
  );
};

export default NotificationItem;
